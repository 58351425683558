import {
  CURRENCY,
  MODALTABLE,
  NUMBER,
  PHONE,
  RADIO,
  SELECT,
  STRING,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import { RiUserStarFill } from "react-icons/ri";
import { SIMPLE } from "../../Component/Sidebar/PageTypes";
import MapTrigger from "./Components/MapTrigger";
import MiqdorSelect from "../../Component/Tables/ModalTable/KirimChiqimInputs/MiqdorSelect";
import ShowAllBtn from "../Savdo/Components/ShowAllBtn";
const Xodimlar = {
  title: "Ҳодимлар",
  path: "/servis/xodimlar",
  icon: <RiUserStarFill></RiUserStarFill>,
  type: SIMPLE,
  innerData: "regionlar",
  columns: [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">ID</div>,
      dataIndex: "Id",
      key: "Id",
      width: "130px",
    },
    {
      title: <div className="table_head">Ф.И.Ш</div>,
      dataIndex: "fio",
      key: "fio",
      width: "215 px",
    },
    {
      title: <div className="table_head">Тури</div>,
      dataIndex: "turi",
      width: "215 px",
      render: (text) => (text == 1 ? "Агент" : "Доставшик"),
    },
    {
      title: <div className="table_head">Вилоят</div>,
      dataIndex: "viloyatlar",
      key: "viloyatlar",
      width: "122px",
    },
    {
      title: <div className="table_head">Шахар / туман</div>,
      dataIndex: "shaharlar",
      key: "shaharlar",
      width: "124px",
    },
    {
      title: <div className="table_head">Локатсия</div>,
      align: "center",
      render: (text, record) => <MapTrigger record={record}></MapTrigger>,
    },
    {
      title: <div className="table_head">Манзил</div>,
      dataIndex: "manzil",
      key: "manzil",
      width: "128px",
    },
    {
      title: <div className="table_head">Телефон рақам</div>,
      dataIndex: "telefon",
      key: "telefon",
      width: "145px",
    },
    {
      title: <div className="table_head">Парол</div>,
      dataIndex: "parol",
      key: "parol",
    },
    {
      title: <div className="table_head">Тўловдан фоиз</div>,
      dataIndex: "tolov_foiz",
      key: "tolov_foiz",
    },
    {
      title: <div className="table_head">Ойлик фикса</div>,
      dataIndex: "tolov_fiksa",
      key: "tolov_fiksa",
    },
  ],
  allData: [
    { value: "shahar_id", prop: "shaharlar", get: "nomi" },
    { value: "viloyat_id", prop: "viloyatlar", get: "nomi" },
  ],
  forms: [
    {
      grid: "1fr 1fr 1fr 1fr",
      inputs: [
        {
          label: "Ф.И.Ш",
          name: "fio",
          type: STRING,
          required: true,
        },
        {
          label: "Вилоят",
          name: "viloyat_id",
          type: SELECT,
          required: true,
          allData: {
            type: "viloyatlar",
            key: "Id",
            text: "nomi",
          },
        },
        {
          label: "Шахар туман",
          name: "shahar_id",
          type: SELECT,
          required: true,
          allData: {
            type: "shaharlar",
            key: "Id",
            text: "nomi",
          },
        },
        {
          label: "Манзил",
          name: "manzil",
          type: STRING,
          required: true,
        },
      ],
    },
    {
      grid: "1fr 1fr 1fr 1fr 1fr",
      inputs: [
        {
          label: "Телефон рақам",
          name: "telefon",
          type: PHONE,
          required: true,
        },
        {
          label: "Парол",
          name: "parol",
          type: STRING,
          required: true,
        },
        {
          label: "Фойдаланувчи тури",
          name: "turi",
          type: RADIO,
          required: true,
          costumData: [
            { Id: 1, nomi: "Агент" },
            { Id: 2, nomi: "Юк етказиб берувчи" },
          ],
        },
        {
          label: "Тўловдан фоиз",
          name: "tolov_foiz",
          condition: {
            value: 1,
            prop: "turi",
          },
          type: CURRENCY,
          required: true,
        },
        {
          label: "Ойлик фикса",
          name: "tolov_fiksa",
          type: CURRENCY,
          required: true,
        },
      ],
    },
    {
      type: "document",
      grid: "1fr",
      inputs: [
        {
          label: "Шахарлар",
          name: "document",
          type: MODALTABLE,
          condition: {
            value: 1,
            prop: "turi",
          },
          innerModal: {
            title: "Шахарлар",
            unique: true,
            allData: "shaharlar",
            columns: [
              {
                title: <div className="table_head">№</div>,
                key: "index",
                render: (text, record, index) => index + 1,
                width: "50px",
                align: "center",
              },
              {
                title: <div className="table_head">Шахар номи</div>,
                dataIndex: "nomi",
                key: "nomi",
              },
            ],
          },

          costumColumns: [
            {
              title: <div className="table_head">№</div>,
              key: "index",
              render: (text, record, index) => index + 1,
              width: "50px",
              align: "center",
            },
            {
              title: <div className="table_head">Шахар номи</div>,
              dataIndex: "Id",
              key: "nomi",
              render: (text) => (
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <MiqdorSelect
                    name={"Id"}
                    value={text}
                    placeholder="Шахар номи"
                    get="nomi"
                    innerDataType={"shaharlar"}
                    // document={true}
                  ></MiqdorSelect>
                  <ShowAllBtn></ShowAllBtn>
                </div>
              ),
            },
          ],
        },
      ],
    },
  ],
};
export default Xodimlar;
