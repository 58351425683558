import { STRING } from "../../Component/Modals/ModalComponents/ModalInputTypes";
import { RiBuilding4Fill } from "react-icons/ri";
import { SIMPLE } from "../../Component/Sidebar/PageTypes";
const XarajatTurlari = {
  title: "Харажат турлари",
  path: "/xarajatturlari",
  icon: <RiBuilding4Fill></RiBuilding4Fill>,
  type: SIMPLE,
  columns: [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">ID</div>,
      dataIndex: "Id",
      width: "100px",
      align: "center",
    },
    {
      title: <div className="table_head">Номи</div>,
      dataIndex: "nomi",
      key: "nomi",
    },
  ],
  forms: [
    {
      grid: "1fr",
      inputs: [
        {
          label: "Номи",
          name: "nomi",
          type: STRING,
          required: true,
        },
      ],
    },
  ],
};

export default XarajatTurlari;
