import { Input } from "antd";
import React from "react";
import { connect } from "react-redux";
import { setDocument } from "../../../../Actions/AppActions";

const IzohInput = ({ setDocument, name, value }) => {
  return <Input name={name} value={value} onChange={setDocument} />;
};

export default connect(null, { setDocument })(IzohInput);
