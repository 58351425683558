import {
  IMAGE,
  STRING,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import { RiBarcodeBoxFill } from "react-icons/ri";
import { BaseUrl } from "../../BaseUrl";
import "react-medium-image-zoom/dist/styles.css";
import Zoom from "react-medium-image-zoom";
import { SIMPLE } from "../../Component/Sidebar/PageTypes";
const Bolimlar = {
  title: "Маҳсулот бўлимлари",
  path: "/servis/bolimlar",
  icon: <RiBarcodeBoxFill></RiBarcodeBoxFill>,
  type: SIMPLE,
  columns: [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">ID</div>,
      dataIndex: "Id",
      width: "100px",
      align: "center",
    },
    {
      title: <div className="table_head">Номи</div>,
      dataIndex: "nomi",
      key: "nomi",
      width: "178px",
    },
    {
      title: <div className="table_head">Расм</div>,
      dataIndex: "img",
      key: "img",
      render: (text) => {
        if (text) {
          return (
            <Zoom zoomMargin={40}>
              <picture>
                <source
                  media="max-width: 200px"
                  srcSet={BaseUrl + text.slice(1)}
                />
                <img src={BaseUrl + text.slice(1)} alt="asda" height={30} />
              </picture>
            </Zoom>
          );
        } else return "";
      },
    },
  ],
  forms: [
    {
      grid: "1fr 2fr",
      inputs: [
        {
          label: "Номи",
          name: "nomi",
          type: STRING,
          required: true,
        },
        {
          label: "Расм",
          name: "img",
          type: IMAGE,
          required: true,
        },
      ],
    },
  ],
};
export default Bolimlar;
