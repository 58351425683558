import _ from "lodash";
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  COSTUM,
  DATE,
  SELECT,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import ModalInputs from "../../Component/Modals/ModalInputs";
import TableGen from "../../Functions/TableGen";
import Refresh from "../Hisobot/components/Refresh";
import BackButton from "../Savdo/Components/BackButton";
import RestrTolovBody from "./RestrTolovBody";

const RestrTolovContainer = ({ allData, hisobot, values }) => {
  const [onPrint, setOnPrint] = useState(true);

  const forms = [
    {
      grid: "1fr 1fr 1fr 1fr 1fr 1fr",
      inputs: [
        {
          label: "Сана",
          name: "sana",
          type: DATE,
        },
        {
          label: "Сана",
          name: "sana2",
          type: DATE,
        },
        {
          label: "Агент",
          name: "agent_id",
          type: SELECT,
          allData: {
            type: "agentlar",
            key: "Id",
            text: "fio",
          },
        },
        {
          label: "Мижоз",
          name: "mijoz_id",
          type: SELECT,
          allData: {
            type: "mijozlar",
            key: "Id",
            text: "fio",
          },
        },
        {
          type: COSTUM,
          component: (
            <div
              style={{ display: "flex", gridGap: "10px", alignItems: "center" }}
            >
              <Refresh text="Янгилаш"></Refresh>
              <BackButton></BackButton>
            </div>
          ),
        },
      ],
    },
  ];

  if (hisobot) {
    let data = [];
    const documents = [];
    let actualHis = [];
    if (values.agent_id) {
      actualHis = hisobot.filter(d => d.agent_id == values.agent_id);
      hisobot
        ?.filter(h => h.agent_id == values.agent_id)
        .forEach(h => {
          h.document.forEach(d => documents.push(d));
        });
    } else {
      actualHis = hisobot;
      hisobot?.forEach(h => {
        h.document?.forEach(d => documents.push(d));
      });
    }

    if (values.mijoz_id) {
      data = _.uniqBy(actualHis, e => e.agent_id).map(d => {
        return {
          ...d,
          document: documents.filter(
            t => t.agent_id == d.agent_id && t.element_id == values.mijoz_id
          ),
        };
      });
    } else {
      data = _.uniqBy(actualHis, e => e.agent_id).map(d => {
        return {
          ...d,
          document: documents.filter(t => t.agent_id == d.agent_id),
        };
      });
    }

    const table = {
      thead: [],
      tbody: RestrTolovBody(data, allData),
    };

    window.onbeforeprint = function () {
      setOnPrint(false);
    };
    window.onafterprint = function () {
      setOnPrint(true);
    };

    return (
      <>
        {onPrint ? (
          <div style={{ padding: "10px", borderBottom: "2px solid black" }}>
            <ModalInputs forms={forms}> </ModalInputs>
          </div>
        ) : null}
        <div style={{ width: "900px" }}>
          <TableGen
            table={table}
            title="Агент тўловларини рўйхатга олиш журнали"
          ></TableGen>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          style={{
            padding: "10px",
          }}
        >
          <ModalInputs forms={forms}> </ModalInputs>
        </div>
      </>
    );
  }
};
const mapStateToProps = state => {
  return {
    allData: state.app.allData,
    hisobot: state.app.hisobot,
    values: state.app.values,
  };
};

export default connect(mapStateToProps)(RestrTolovContainer);
