import moment from "moment";
import { RiFileList3Fill } from "react-icons/ri";
import {
  COSTUM,
  DATE,
  SELECT,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import { HISOBOT } from "../../Component/Sidebar/PageTypes";
import PrintBtn from "./components/PrintBtn";
import Refresh from "./components/Refresh";
const MaxsulotHisoboti = {
  title: "Маҳсулот қолдиги",
  path: "/hisobot/maxsulot-qoldigi",
  icon: <RiFileList3Fill></RiFileList3Fill>,
  type: HISOBOT,
  allData: [
    { value: "brend_id", prop: "brendlar", get: "nomi" },
    { value: "tovar_id", prop: "tovarlar", get: "nomi" },
  ],
  hisobotActions: [
    {
      grid: "2fr 2fr 4fr 1fr",
      inputs: [
        {
          label: "Сана",
          name: "sana3",
          type: DATE,
        },
        {
          label: "Бренд",
          name: "brend_id",
          type: SELECT,
          allData: {
            type: "brendlar",
            key: "Id",
            text: "nomi",
          },
        },
        {
          label: "Махсулотлар",
          name: "maxsulot_id",
          type: SELECT,
          allData: {
            dependent: "brend_id",
            type: "tovarlar",
            key: "Id",
            text: "nomi",
          },
        },
        {
          type: COSTUM,
          component: (
            <div
              style={{ display: "flex", gridGap: "10px", alignItems: "center" }}
            >
              <Refresh text="Янгилаш"></Refresh>
              <PrintBtn></PrintBtn>
            </div>
          ),
        },
      ],
    },
  ],
};
export default MaxsulotHisoboti;
