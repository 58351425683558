import {
  COSTUM,
  CURRENCY,
  DATE,
  SELECT,
  STRING,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import { RiUser2Fill } from "react-icons/ri";
import "react-medium-image-zoom/dist/styles.css";
import { SIMPLE } from "../../Component/Sidebar/PageTypes";
import NumberFormat from "../../Functions/NumberFormat";
import SanaFormat from "../../Functions/SanaFormat";
const XarajatTolov = {
  title: "Харажат тўлов",
  path: "/xarajat-tolov",
  icon: <RiUser2Fill></RiUser2Fill>,
  type: SIMPLE,
  columns: [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">ID</div>,
      dataIndex: "Id",
      width: "80px",
      align: "center",
    },
    {
      title: <div className="table_head">Сана</div>,
      dataIndex: "sana",
      key: "sana",
      width: "80px",
      render: (text) => SanaFormat(text),
    },
    {
      title: <div className="table_head">Тўлов тури</div>,
      dataIndex: "kassa_turlari",
      width: "150px",
    },
    {
      title: <div className="table_head">Тўлов</div>,
      children: [
        {
          title: <div className="table_head">Сўм</div>,
          dataIndex: "summa_som",
          align: "right",
          width: "120px",
          render: (text) => NumberFormat(text),
        },
        {
          title: <div className="table_head">Переч</div>,
          dataIndex: "summa_perech",
          align: "right",
          width: "100px",
          render: (text) => NumberFormat(text),
        },
        {
          title: <div className="table_head">Валюта</div>,
          dataIndex: "summa_val",
          align: "right",
          width: "100px",
          render: (text) => NumberFormat(text, 1) + " $",
        },
      ],
    },
    {
      title: <div className="table_head">Изох</div>,
      dataIndex: "izoh",
      key: "viloyatlar",
    },
  ],
  allData: [
    { value: "kassa_id", prop: "kassa_turlari", get: "nomi" },
    { value: "tolov_turi", prop: "tolov_turlari", get: "nomi" },
  ],
  forms: [
    {
      grid: "1fr 1fr 1fr 1fr 1fr",
      inputs: [
        {
          label: "Сана",
          name: "sana",
          type: DATE,
        },
        {
          label: "Харажат тури",
          name: "kassa_id",
          type: SELECT,
          required: true,
          allData: {
            type: "kassa_turlari",
            key: "Id",
            filters: {
              prop: "Id",
              condition: "greater",
              value: 3,
            },
            text: "nomi",
          },
        },
        {
          label: "Тўлов тури",
          name: "tolov_turi",
          type: SELECT,
          required: true,
          allData: {
            type: "tolov_turlari",
            key: "Id",
            text: "nomi",
          },
        },
        {
          label: "Сумма",
          name: "summa",
          type: CURRENCY,
        },
        {
          label: "Изох",
          name: "izoh",
          type: STRING,
        },
      ],
    },
  ],
};
export default XarajatTolov;
