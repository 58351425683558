import React from "react";
import HtmlParser from "react-html-parser";
import { connect } from "react-redux";

const HisobotContainer = ({ hisobot }) => {
  return (
    <>
      <div
        style={{
          overflowX: "auto",
          //   overflowY: "calc(100vh - 220px)",
          //   maxHeight: "calc(100vh - 220px)",
        }}
      >
        {hisobot && hisobot.html ? HtmlParser(hisobot.html) : ""}
      </div>
    </>
  );
};
const mapStateToProps = state => {
  return {
    hisobot: state.app.hisobot,
  };
};
export default connect(mapStateToProps, {})(HisobotContainer);
