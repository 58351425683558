import { Input } from "antd";
import { values } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { setDocument, stateDetector } from "../../../../Actions/AppActions";
import NumberFormat from "../../../../Functions/NumberFormat";

const MiqdorOstatka = ({
  setDocument,
  name,
  value,
  type,
  qoldiq,
  miqdor,
  blok_soni,
  stateDetector,
}) => {
  let red = "";
  const onChange = (e) => {
    if (qoldiq) {
      if (type == "blok") {
        const ost_blok = qoldiq / blok_soni;
        if (e.target.value <= ost_blok) {
          const miqdor_soni = e.target.value * blok_soni;
          const obj = { target: { name: "miqdor_soni", value: miqdor_soni } };
          setDocument(e);
          setDocument(obj);
        }
      } else {
        if (e.target.value <= qoldiq) {
          const miqdor_blok = e.target.value / blok_soni;
          const obj = {
            target: {
              name: "miqdor_blok",
              value: NumberFormat(miqdor_blok, 1),
            },
          };
          setDocument(e);
          setDocument(obj);
        }
      }
    } else {
      if (type == "blok") {
        const miqdor_soni = e.target.value * blok_soni;
        const obj = { target: { name: "miqdor_soni", value: miqdor_soni } };
        setDocument(e);
        setDocument(obj);
      } else {
        const miqdor_blok = e.target.value / blok_soni;
        const obj = {
          target: { name: "miqdor_blok", value: NumberFormat(miqdor_blok, 1) },
        };
        setDocument(e);
        setDocument(obj);
      }
    }

    stateDetector();
  };

  if (name == "sotish_soni" && qoldiq && miqdor > qoldiq && value > 0) {
    red = "qoldiq_red";
  }

  return (
    <Input
      min={0}
      value={value}
      type="number"
      name={name}
      className={red}
      onChange={onChange}
    />
  );
};

export default connect(null, { setDocument, stateDetector })(MiqdorOstatka);
