import Checkbox from "antd/lib/checkbox/Checkbox";
import React from "react";
import { connect } from "react-redux";
import { setDocument } from "../../../../Actions/AppActions";

const CheckboxMiqdor = ({ value, name, setDocument }) => {
  function onChange(e) {
    setDocument({ target: { name, value: e.target.checked } });
  }
  return <Checkbox checked={value} onChange={onChange}></Checkbox>;
};

export default connect(null, { setDocument })(CheckboxMiqdor);
