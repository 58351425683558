import { RiBriefcaseFill } from "react-icons/ri";
import MaxsulotQoldigi from "../Savdo/MaxsulotQoldigi";
import MijozQoldigi from "../Savdo/MijozQoldigi";
import YukBeruvchilarQoldigi from "../Savdo/YukBeruvchilarQoldigi";
import Bolimlar from "./Bolimlar";
import Brendlar from "./Brendlar";
import Maxsulot from "./Maxsulot";
import Mijozlar from "./Mijozlar";
import OlchovBirligi from "./OlchovBirligi";
import Region from "./Region";
import Shahar from "./Shahar";
import Viloyat from "./Viloyat";
import Xodimlar from "./Xodimlar";
import yukBeruvchi from "./yukBeruvchi";
export default () => {
  return [
    yukBeruvchi,
    Viloyat,
    // {
    //   title: "Лавозим",
    //   path: "/servis/lavozim",
    //   icon: <RiBriefcaseFill></RiBriefcaseFill>,
    // },
    Xodimlar,
    Brendlar,
    Shahar,
    MaxsulotQoldigi,
    OlchovBirligi,
    Region,
    YukBeruvchilarQoldigi,
    Bolimlar,
    Mijozlar,
    MijozQoldigi,
    Maxsulot,
  ];
};
