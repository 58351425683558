import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import fetchApi from "../../../../Functions/fetchApi";
import { setDocument } from "../../../../Actions/AppActions";
const DocumentQarzi = ({
  turi,
  agent_id,
  mijoz_id,
  xodim_id,
  sana,
  setDocument,
  qarzi,
}) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    console.log(xodim_id);
    if ((agent_id && mijoz_id) || xodim_id) {
      fetchQarzi(
        {
          agent_id,
          mijoz_id,
          xodim_id,
          sana: sana ? sana : moment().format("YYYY-MM-DD HH:mm:ss"),
        },
        turi
      );
    }
  }, [agent_id, mijoz_id, xodim_id]);

  const fetchQarzi = async (obj, turi) => {
    console.log(obj);
    setLoading(true);
    let res = "";
    if (turi == 1) {
      res = await fetchApi("/mijozqarzi", "POST", obj);
    } else {
      res = await fetchApi("/yukberuvchi_qarzi", "POST", obj);
    }

    if (res && res.status == 200) {
      setDocument({
        target: {
          name: "qarzi_som",
          value: res.data.length > 0 ? res.data[0].summa_som : 0,
        },
      });
      setDocument({
        target: {
          name: "qarzi_val",
          value: res.data.length > 0 ? res.data[0].summa_val : 0,
        },
      });
      console.log(res.data);
    }

    setLoading(false);
  };
  return loading ? <LoadingOutlined /> : <div>{qarzi}</div>;
};

export default connect(null, { setDocument })(DocumentQarzi);
