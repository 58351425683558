import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setCurrentPage } from "../Actions/AppActions";

const ServiceItem = ({ obj, setCurrentPage }) => {
  return (
    <Link onClick={() => setCurrentPage(obj)} to={obj.path}>
      <div className="service_item">
        {obj.icon}
        {obj.title}
      </div>
    </Link>
  );
};

export default connect(null, { setCurrentPage })(ServiceItem);
