import MiqdorInput from "../../Component/Tables/ModalTable/KirimChiqimInputs/MiqdorInput";
import { RiStackFill } from "react-icons/ri";
import {
  DATE,
  MODALTABLE,
  STRING,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import { SIMPLE } from "../../Component/Sidebar/PageTypes";
import MiqdorSelect from "../../Component/Tables/ModalTable/KirimChiqimInputs/MiqdorSelect";
import DocumentClass from "../../Functions/DocumentClass";
import NumberFormat from "../../Functions/NumberFormat";
import moment from "moment";
import ShowAllBtn from "./Components/ShowAllBtn";
import SanaFormat from "../../Functions/SanaFormat";
const MijozQoldigi = {
  title: "Мижозлар қолдиғи",
  path: "/mijoz-qoldigi",
  type: SIMPLE,
  icon: <RiStackFill></RiStackFill>,
  allData: [
    { value: "mijoz_id", prop: "mijozlar", get: "fio" },
    { value: "agent_id", prop: "agentlar", get: "fio" },
  ],
  defaultValues: [
    {
      prop: "turi",
      value: 2,
    },
  ],
  modalJami: "mijoz-qoldigi",
  innerData: "mijozlar",
  columns: [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">ID</div>,
      dataIndex: "Id",
      width: "100px",
      align: "center",
    },
    {
      title: <div className="table_head">Сана</div>,
      dataIndex: "time_create",
      key: "nomi",
      width: "150px",
      render: (text) => SanaFormat(text),
    },
    {
      title: <div className="table_head">Қарзингиз</div>,
      children: [
        {
          title: <div className="table_head">Сўм</div>,
          dataIndex: "document",
          key: "nomi",
          width: "150px",
          align: "right",
          render: (text) =>
            NumberFormat(DocumentClass(text, "yukberuvchi-qoldigi").debit_som),
        },
        {
          title: <div className="table_head">Валюта</div>,
          dataIndex: "document",
          key: "nomi",
          width: "150px",
          align: "right",
          render: (text) =>
            NumberFormat(
              DocumentClass(text, "yukberuvchi-qoldigi").debit_val,
              1
            ) + " $",
        },
      ],
    },
    {
      title: <div className="table_head">Хаққингиз</div>,
      children: [
        {
          title: <div className="table_head">Сўм</div>,
          dataIndex: "document",
          key: "nomi",
          width: "150px",
          align: "right",
          render: (text) =>
            NumberFormat(DocumentClass(text, "yukberuvchi-qoldigi").kredit_som),
        },
        {
          title: <div className="table_head">Валюта</div>,
          dataIndex: "document",
          key: "nomi",
          width: "150px",
          align: "right",
          render: (text) =>
            NumberFormat(
              DocumentClass(text, "yukberuvchi-qoldigi").kredit_val,
              1
            ) + " $",
        },
      ],
    },
    {
      title: <div className="table_head">Изох</div>,
      dataIndex: "izoh",
      key: "nomi",
      width: "178px",
    },
  ],
  forms: [
    {
      type: "document",
      grid: "1fr",
      actionInputs: [
        {
          grid: "2fr 4fr",
          inputs: [
            {
              label: "Сана",
              name: "time_create",
              type: DATE,
            },
            {
              label: "Изох",
              name: "izoh",
              type: STRING,
            },
          ],
        },
      ],
      inputs: [
        {
          name: "document",
          type: MODALTABLE,
          innerModal: {
            title: "Мижозлар",
            columns: [
              {
                title: <div className="table_head">№</div>,
                key: "index",
                render: (text, record, index) => index + 1,
                width: "50px",
                align: "center",
              },
              {
                title: <div className="table_head">Номи</div>,
                dataIndex: "fio",
                key: "nomi",
              },
            ],
          },
          costumColumns: [
            {
              title: <div className="table_head">№</div>,
              key: "index",
              render: (text, record, index) => index + 1,
              width: "70px",
              align: "center",
            },
            {
              title: <div className="table_head">Мижоз</div>,
              dataIndex: "element_id",
              key: "nomi",
              render: (text) => (
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <MiqdorSelect
                    name={"element_id"}
                    value={text}
                    placeholder="Мижоз"
                    get="fio"
                    allDataType={"mijozlar"}
                  ></MiqdorSelect>
                  <ShowAllBtn></ShowAllBtn>
                </div>
              ),
            },
            {
              title: <div className="table_head">Агент</div>,
              dataIndex: "agent_id",
              key: "nomi",
              render: (text) => (
                <MiqdorSelect
                  name={"agent_id"}
                  value={text}
                  get="fio"
                  placeholder="Агент"
                  allDataType={"agentlar"}
                ></MiqdorSelect>
              ),
            },
            {
              title: <div className="table_head">Қарзингиз</div>,
              children: [
                {
                  title: <div className="table_head">сўм</div>,
                  dataIndex: "debit_som",
                  key: "nomi",
                  render: (text) => (
                    <MiqdorInput name="debit_som" value={text}></MiqdorInput>
                  ),
                  width: "170px",
                },
                {
                  title: <div className="table_head">валюта</div>,
                  dataIndex: "debit_val",
                  key: "nomi",
                  render: (text) => (
                    <MiqdorInput name="debit_val" value={text}></MiqdorInput>
                  ),
                  width: "120px",
                },
              ],
            },
            {
              title: <div className="table_head">Хаққингиз</div>,
              children: [
                {
                  title: <div className="table_head">сўм</div>,
                  dataIndex: "kredit_som",
                  key: "nomi",
                  render: (text) => (
                    <MiqdorInput name="kredit_som" value={text}></MiqdorInput>
                  ),
                  width: "170px",
                },

                {
                  title: <div className="table_head">валюта</div>,
                  dataIndex: "kredit_val",
                  key: "nomi",
                  render: (text) => (
                    <MiqdorInput name="kredit_val" value={text}></MiqdorInput>
                  ),
                  width: "120px",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default MijozQoldigi;
