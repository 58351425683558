import {
  PHONE,
  STRING,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import { RiUserShared2Fill } from "react-icons/ri";
import { SIMPLE } from "../../Component/Sidebar/PageTypes";
const yukBeruvchi = {
  title: "Юк берувчи",
  path: "/servis/yukBeruvchi",
  icon: <RiUserShared2Fill></RiUserShared2Fill>,
  type: SIMPLE,
  modalWidth: "600px",
  columns: [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">ID</div>,
      dataIndex: "Id",
      width: "100px",
      align: "center",
    },
    {
      title: <div className="table_head">Ф.И.Ш</div>,
      dataIndex: "fio",
      key: "fio",
      width: "208px",
    },
    {
      title: <div className="table_head">Телефон рақами</div>,
      dataIndex: "telefon",
      key: "telefon",
      width: "171px",
    },
    {
      title: <div className="table_head">Бошқарувчи</div>,
      dataIndex: "boshliq",
      key: "boshliq",
      width: "171px",
    },
    {
      title: <div className="table_head">Бошлиқ телефони</div>,
      dataIndex: "boshliq_tel",
      key: "boshliq_tel",
    },
  ],
  forms: [
    {
      grid: "2fr 1fr",
      inputs: [
        {
          label: "F.I.O",
          name: "fio",
          type: STRING,
          required: true,
        },
        {
          label: "Телефон рақам",
          name: "telefon",
          type: PHONE,
          required: true,
        },
      ],
    },
    {
      grid: "2fr 1fr",
      inputs: [
        {
          label: "Бошлиқ",
          name: "boshliq",
          type: STRING,
          required: true,
        },
        {
          label: "Бошлиқнинг телефон рақами",
          name: "boshliq_tel",
          type: PHONE,
          required: true,
        },
      ],
    },
  ],
};

export default yukBeruvchi;
