import DocumentClass from "./DocumentClass";

export default (turi, data) => {
  switch (turi) {
    // case "kirimChiqim":
    //   if (data.length > 0) {
    //     let obj = {};
    //     let kirim_jami = 0;
    //     let sotish_jami = 0;
    //     let miqdor_jami = 0;
    //     data.forEach(d => {
    //       kirim_jami += DocumentClass(d.document).kirim_summa;
    //       sotish_jami += DocumentClass(d.document).sotish_summa;
    //       miqdor_jami += DocumentClass(d.document).miqdor_summa;
    //     });

    //     if (data[0].document.length > 0) {
    //       obj = {
    //         ...data[0].document[0],
    //         kirim_narxi: kirim_jami,
    //         sotish_narxi: sotish_jami,
    //         miqdor: miqdor_jami,
    //       };
    //     }
    //     return obj;
    //   }
    //   break;

    case "maxsulot-qoldigi":
      const arrmax = [
        // "",
        "",
        DocumentClass(data, turi).miqdor_summa,
        "",
        "",
        DocumentClass(data, turi).kirim_summa,
        DocumentClass(data, turi).sotish_summa,
      ];
      return arrmax;

    case "yukberuvchi-qoldigi":
      const arryuk = [
        DocumentClass(data, turi).debit_som,
        DocumentClass(data, turi).debit_val + " $",
        DocumentClass(data, turi).kredit_som,
        DocumentClass(data, turi).kredit_val + " $",
      ];
      return arryuk;
    case "mijoz-qoldigi":
      const arrmij = [
        "",
        DocumentClass(data, turi).debit_som,
        DocumentClass(data, turi).debit_val,
        DocumentClass(data, turi).kredit_som,
        DocumentClass(data, turi).kredit_val,
      ];
      return arrmij;
    case "maxsulot-kirimi":
      const arrkirim = [
        "",
        "",
        DocumentClass(data, turi).miqdor_blok,
        DocumentClass(data, turi).miqdor_soni,
        "",
        "",
        "",
        "",
        "",
        DocumentClass(data, turi).kirim_summa,
        DocumentClass(data, turi).kirim_val_summa + " $",
        "",
      ];
      return arrkirim;
    case "yuk_beruvchi_vozvrat":
      const arrkirim2 = [
        "",
        "",
        DocumentClass(data, turi).miqdor_blok,
        DocumentClass(data, turi).miqdor_soni,
        "",
        "",
        "",
        "",
        "",
        DocumentClass(data, turi).kirim_summa,
        DocumentClass(data, turi).kirim_val_summa,
        "",
      ];
      return arrkirim2;

    case "sotuv-bolimi":
      const arrsotuv = [
        "",
        "",
        DocumentClass(data, turi).miqdor_blok,
        DocumentClass(data, turi).miqdor_soni,
        "",
        "",
        DocumentClass(data, turi).sotish_summa,
        DocumentClass(data, turi).sotish_val_summa,
      ];
      return arrsotuv;

    case "agent-buyurtma":
      const arragent = [
        "",
        "",
        "",
        DocumentClass(data, turi).miqdor_blok,
        DocumentClass(data, turi).miqdor_soni,
        "",
        "",
        DocumentClass(data, turi).sotish_val_summa,
        DocumentClass(data, turi).sotish_summa,
      ];
      return arragent;
    case "mijoz-buyurtma":
      const arrmijoz = [
        "",
        "",
        "",
        DocumentClass(data, turi).miqdor_blok,
        "",
        DocumentClass(data, turi).miqdor_soni,
        "",
        "",
        DocumentClass(data, turi).sotish_val_summa,
        DocumentClass(data, turi).sotish_summa,
      ];
      return arrmijoz;
    case "agent-tolov":
      const arrtolov = [
        "",
        "",
        "",
        "",
        DocumentClass(data, turi).summa_som +
          DocumentClass(data, turi).summa_perech +
          DocumentClass(data, turi).summa_val,
        "",
        "",
        "",
      ];
      return arrtolov;
    case "yuk-tolov":
      const arrYuktolov = [
        "",
        "",
        "",
        DocumentClass(data, turi).summa_som +
          DocumentClass(data, turi).summa_perech +
          DocumentClass(data, turi).summa_val,
        "",
        "",
        "",
      ];
      return arrYuktolov;
    default:
      break;
  }
};
