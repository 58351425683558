import {
  PHONE,
  SELECT,
  STRING,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import { RiUser2Fill } from "react-icons/ri";
import "react-medium-image-zoom/dist/styles.css";
import { SIMPLE } from "../../Component/Sidebar/PageTypes";
const Mijozlar = {
  title: "Мижозлар",
  path: "/servis/mijozlar",
  icon: <RiUser2Fill></RiUser2Fill>,
  type: SIMPLE,
  defaultValues: [
    {
      prop: "turi",
      value: 3,
    },
  ],
  columns: [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">ID</div>,
      dataIndex: "Id",
      width: "100px",
      align: "center",
    },
    {
      title: <div className="table_head">Ф.И.Ш</div>,
      dataIndex: "fio",
      key: "fio",
      width: "152px",
    },
    {
      title: <div className="table_head">Вилоят</div>,
      dataIndex: "viloyatlar",
      key: "viloyatlar",
      width: "122px",
    },
    {
      title: <div className="table_head">Шахар / туман</div>,
      dataIndex: "shaharlar",
      key: "shaharlar",
      width: "158px",
    },
    {
      title: <div className="table_head">Манзил</div>,
      dataIndex: "manzil",
      key: "manzil",
      width: "141px",
    },
    {
      title: <div className="table_head">Парол</div>,
      dataIndex: "parol",
      key: "parol",
      width: "94px",
    },
    {
      title: <div className="table_head">Телефон рақам</div>,
      dataIndex: "telefon",
      key: "telefon",
      width: "147px",
      render: (text) => {
        return "+" + text;
      },
    },
  ],
  allData: [
    { value: "viloyat_id", prop: "viloyatlar", get: "nomi" },
    { value: "shahar_id", prop: "shaharlar", get: "nomi" },
    { value: "region_id", prop: "regionlar", get: "nomi" },
  ],
  forms: [
    {
      grid: "1fr 1fr 1fr 1fr",
      inputs: [
        {
          label: "Ф.И.О",
          name: "fio",
          type: STRING,
          required: true,
        },
        {
          label: "Вилоят",
          name: "viloyat_id",
          type: SELECT,
          required: true,
          allData: {
            type: "viloyatlar",
            key: "Id",
            text: "nomi",
          },
        },
        {
          label: "Шахар / Туман",
          name: "shahar_id",
          type: SELECT,
          required: true,
          allData: {
            dependent: "viloyat_id",
            type: "shaharlar",
            key: "Id",
            text: "nomi",
          },
        },
        {
          label: "Регион",
          name: "region_id",
          type: SELECT,
          allData: {
            dependent: "shahar_id",
            type: "regionlar",
            key: "Id",
            text: "nomi",
          },
        },
      ],
    },

    {
      grid: "2fr 1fr 1fr",
      inputs: [
        {
          label: "Манзил",
          name: "manzil",
          type: STRING,
          required: true,
        },
        {
          label: "Телефон рақам",
          name: "telefon",
          type: PHONE,
          required: true,
        },
        {
          label: "Парол",
          name: "parol",
          type: STRING,
          required: true,
        },
      ],
    },
  ],
};
export default Mijozlar;
