import MiqdorInput from "../../Component/Tables/ModalTable/KirimChiqimInputs/MiqdorInput";
import { RiStackFill } from "react-icons/ri";
import {
  DATE,
  MODALTABLE,
  STRING,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import { SIMPLE } from "../../Component/Sidebar/PageTypes";
import MiqdorSelect from "../../Component/Tables/ModalTable/KirimChiqimInputs/MiqdorSelect";
import DocumentClass from "../../Functions/DocumentClass";
import NumberFormat from "../../Functions/NumberFormat";
import IzohInput from "../../Component/Tables/ModalTable/KirimChiqimInputs/IzohInput";
import SanaFormat from "../../Functions/SanaFormat";
import DocumentQarzi from "../../Component/Tables/ModalTable/KirimChiqimInputs/DocumentQarzi";
import ShowAllBtn from "../Savdo/Components/ShowAllBtn";
import SanaReturn from "../../Component/Tables/ModalTable/KirimChiqimInputs/SanaReturn";
const YukBeruvchiTolov = {
  title: "Юк берувчи тўлов",
  path: "/yuk-beruvchi-tolov",
  type: SIMPLE,
  icon: <RiStackFill></RiStackFill>,
  allData: [
    { value: "yuk_beruvchi_id", prop: "yuk_beruvchilar", get: "fio" },
    { value: "tolov_turi", prop: "tolov_turlari", get: "nomi" },
  ],
  modalJami: "yuk-tolov",
  innerData: "yuk_beruvchilar",
  columns: [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">ID</div>,
      dataIndex: "Id",
      width: "100px",
      align: "center",
    },
    {
      title: <div className="table_head">Сана</div>,
      dataIndex: "time_create",
      key: "nomi",
      width: "150px",
      render: (text) => SanaFormat(text),
    },
    {
      title: <div className="table_head">Жами сумма</div>,
      dataIndex: "document",
      key: "nomi",
      width: "150px",
      align: "right",
      render: (text) =>
        NumberFormat(DocumentClass(text, "yuk-tolov").summa_som),
    },
    {
      title: <div className="table_head">Жами сумма переч</div>,
      dataIndex: "document",
      key: "nomi",
      width: "150px",
      align: "right",
      render: (text) =>
        NumberFormat(DocumentClass(text, "yuk-tolov").summa_perech),
    },
    {
      title: <div className="table_head">Жами сумма вал</div>,
      dataIndex: "document",
      key: "nomi",
      width: "150px",
      align: "right",
      render: (text) =>
        NumberFormat(DocumentClass(text, "yuk-tolov").summa_val, 1) + " $",
    },
    {
      title: <div className="table_head">Изох</div>,
      dataIndex: "izoh",
      key: "nomi",
      width: "178px",
    },
  ],
  forms: [
    {
      type: "document",
      grid: "1fr",
      actionInputs: [
        {
          grid: "2fr 4fr",
          inputs: [
            {
              label: "Сана",
              name: "time_create",
              type: DATE,
            },
            {
              label: "Изох",
              name: "izoh",
              type: STRING,
            },
          ],
        },
      ],
      inputs: [
        {
          name: "document",
          type: MODALTABLE,
          innerModal: {
            title: "Юк берувчилар",
            columns: [
              {
                title: <div className="table_head">№</div>,
                key: "index",
                render: (text, record, index) => index + 1,
                width: "50px",
                align: "center",
              },
              {
                title: <div className="table_head">Номи</div>,
                dataIndex: "fio",
                key: "nomi",
              },
            ],
          },
          costumColumns: [
            {
              title: <div className="table_head">№</div>,
              key: "index",
              render: (text, record, index) => index + 1,
              width: "70px",
              align: "center",
            },
            {
              title: <div className="table_head">Юк берувчи</div>,
              dataIndex: "element_id",
              key: "nomi",
              width: "250px",
              render: (text) => (
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <MiqdorSelect
                    name={"element_id"}
                    value={text}
                    placeholder="Юк берувчи"
                    get="fio"
                    cursor="1"
                    allDataType={"yuk_beruvchilar"}
                  ></MiqdorSelect>
                  <ShowAllBtn></ShowAllBtn>
                </div>
              ),
            },
            {
              title: <div className="table_head">Қарзи сўм</div>,
              dataIndex: "qarzi_som",
              key: "nomi",
              width: "130px",
              render: (text, record) => (
                <DocumentQarzi
                  turi="2"
                  xodim_id={record.element_id}
                  qarzi={NumberFormat(record.qarzi_som, 2)}
                ></DocumentQarzi>
              ),
            },
            {
              title: <div className="table_head">Қарзи вал</div>,
              dataIndex: "qarzi_val",
              key: "nomi",
              width: "100px",
              render: (text, record) => NumberFormat(text, 1) + " $",
            },
            {
              title: <div className="table_head">Тўлов тури</div>,
              dataIndex: "tolov_turi",
              key: "nomi",
              width: "140px",
              render: (text) => (
                <MiqdorSelect
                  name={"tolov_turi"}
                  value={text}
                  get="nomi"
                  placeholder="Тўлов тури"
                  allDataType={"tolov_turlari"}
                ></MiqdorSelect>
              ),
            },
            {
              title: <div className="table_head">Сумма</div>,
              dataIndex: "summa_som",
              key: "nomi",
              render: (text) => (
                <MiqdorInput name="summa_som" value={text}></MiqdorInput>
              ),
              width: "140px",
            },
            {
              title: <div className="table_head">Изох</div>,
              dataIndex: "izoh",
              render: (text) => (
                <IzohInput name="izoh" value={text}></IzohInput>
              ),
            },
          ],
        },
      ],
    },
  ],
};

export default YukBeruvchiTolov;
