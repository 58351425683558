import {
  RiDatabase2Fill,
  RiHome2Fill,
  RiPaypalFill,
  RiReplyAllFill,
  RiShoppingBag3Fill,
  RiVipDiamondFill,
} from "react-icons/ri";
import { SERVICES, SIMPLE } from "../Component/Sidebar/PageTypes";
import AgentBuyurtma from "./Savdo/AgentBuyurtma";
import YukVozvrat from "./Savdo/DostavchikVozvrat";
import MaxsulotKirimi from "./Savdo/MaxsulotKirimi";
import MijozBuyurtma from "./Savdo/MijozBuyurtma";
import Savdodan from "./Savdo/Qaytarilganlar";
import SotuvBolimi from "./Savdo/SotuvBolimi";
import AgentTolov from "./Tolovlar/AgentTolov";
import MijozTolov from "./Tolovlar/MijozTolov";
import YukBeruvchiTolov from "./Tolovlar/YukBeruvchiTolov";
import XarajatTolov from "./Xarajatlar/XarajatTolov";
import XarajatTurlari from "./Xarajatlar/XarajatTurlari";

const PagesClass = [
  // {
  //   title: "Бош меню",
  //   path: "/",
  //   icon: <RiHome2Fill></RiHome2Fill>,
  //   type: SIMPLE,
  // },
  MaxsulotKirimi,
  {
    title: "Савдо бўлими",
    key: "sub1",
    icon: <RiShoppingBag3Fill></RiShoppingBag3Fill>,
    submenu: [SotuvBolimi, AgentBuyurtma, MijozBuyurtma],
  },
  {
    key: "sub2",
    title: "Қайтарилганлар",
    icon: <RiReplyAllFill></RiReplyAllFill>,
    submenu: [Savdodan, YukVozvrat],
  },
  {
    key: "sub3",
    title: "Тўловлар",
    icon: <RiPaypalFill></RiPaypalFill>,
    submenu: [MijozTolov, AgentTolov, YukBeruvchiTolov],
  },
  {
    key: "sub4",
    title: "Харажатлар",
    icon: <RiPaypalFill></RiPaypalFill>,
    submenu: [XarajatTurlari, XarajatTolov],
  },
  {
    title: "Сервис",
    path: "/service",
    icon: <RiVipDiamondFill></RiVipDiamondFill>,
    type: SERVICES,
  },
  {
    title: "Хисобот",
    path: "/hisobot",
    icon: <RiDatabase2Fill></RiDatabase2Fill>,
    type: SERVICES,
  },
];

export default PagesClass;
