import { RiServerFill } from "react-icons/ri";
import {
  COSTUM,
  DATE,
  SELECT,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import { HISOBOT } from "../../Component/Sidebar/PageTypes";
import PrintBtn from "./components/PrintBtn";
import Refresh from "./components/Refresh";
const KassaHisobot = {
  title: "Касса хисоби",
  path: "/hisobot/kassa-hisobi",
  icon: <RiServerFill></RiServerFill>,
  type: HISOBOT,
  allData: [{ value: "kassa_turi", prop: "kassa_turlari", get: "nomi" }],
  hisobotActions: [
    {
      grid: "1fr 1fr 1fr 1fr",
      inputs: [
        {
          label: "Сана 1",
          name: "sana1",
          type: DATE,
        },
        {
          label: "Сана 2",
          name: "sana2",
          type: DATE,
          secondDate: true,
        },
        {
          label: "Касса турлари",
          name: "kassa_turi",
          type: SELECT,
          allData: {
            type: "kassa_turlari",
            key: "Id",
            text: "nomi",
          },
        },
        {
          type: COSTUM,
          component: (
            <div
              style={{ display: "flex", gridGap: "10px", alignItems: "center" }}
            >
              <Refresh text="Янгилаш"></Refresh>
              <Refresh
                text="Жамлаб кўрсатиш"
                color="red"
                group={true}
              ></Refresh>
              <PrintBtn></PrintBtn>
            </div>
          ),
        },
      ],
    },
  ],
};
export default KassaHisobot;
