import NumberFormat from "../../Functions/NumberFormat";

export default (hisobot, allData, summary) => {
  const body = [];
  let all_miqdor = 0;
  let all_summa_som = 0;
  let all_summa_val = 0;
  let all_qarzi_som = 0;
  let all_qarzi_val = 0;
  hisobot.forEach((d) => {
    body.push({
      br: true,
      className: "tbody-th-bg-naklad",
      data: [
        { value: d.Id, styles: { fontFamily: "Arial, Helvetica, sans-serif" } },
        {
          value: d.sana,
          styles: { fontFamily: "Arial, Helvetica, sans-serif" },
        },
        {
          value: allData.mijozlar.find((m) => m.Id == d.mijoz_id)
            ? allData.mijozlar.find((m) => m.Id == d.mijoz_id).fio
            : null,
          cols: 2,
          styles: { fontFamily: "Arial, Helvetica, sans-serif" },
        },
        {
          value: allData.agentlar.find((m) => m.Id == d.agent_id)
            ? allData.agentlar.find((m) => m.Id == d.agent_id).fio
            : null,
          cols: 4,
          styles: { fontFamily: "Arial, Helvetica, sans-serif" },
        },
        {
          value: NumberFormat(d.qarzi_som, 2),
          cols: 1,
          styles: { fontFamily: "Arial, Helvetica, sans-serif" },
        },
        {
          value: NumberFormat(d.qarzi_val, 2),
          cols: 1,
          styles: { fontFamily: "Arial, Helvetica, sans-serif" },
        },
      ],
    });

    body.push({
      data: [
        {
          value: <div style={{ height: "5px", width: "100%" }}></div>,
          styles: {
            borderRight: "1px solid #fff",
            borderLeft: "1px solid #fff",
          },
        },
      ],
    });
    if (d.document) {
      body.push({
        data: [
          {
            value: "№",
            styles: { borderLeft: "1px solid #000", fontWeight: "600" },
          },
          {
            value: "Махсулот",
            styles: {
              fontWeight: "600",
              fontFamily: "Arial, Helvetica, sans-serif",
            },
          },
          {
            value: "Миқдор",
            styles: {
              fontWeight: "600",
              fontFamily: "Arial, Helvetica, sans-serif",
            },
          },
          {
            value: "Нархи сўм",
            styles: {
              fontWeight: "600",
              fontFamily: "Arial, Helvetica, sans-serif",
            },
          },
          {
            value: "Нархи вал",
            styles: {
              fontWeight: "600",
              fontFamily: "Arial, Helvetica, sans-serif",
            },
          },
          {
            value: "Сумма сўм",
            styles: {
              fontWeight: "600",
              fontFamily: "Arial, Helvetica, sans-serif",
            },
          },
          {
            value: "Сумма вал",
            styles: {
              fontWeight: "600",
              fontFamily: "Arial, Helvetica, sans-serif",
            },
          },
          { styles: { border: "1px solid #fff", fontWeight: "600" } },
        ],
      });
      let summa_total_som = 0;
      let summa_total_val = 0;
      let total_miqdor = 0;
      d.document.forEach((d, index) => {
        const tovar = allData.tovarlar.find((t) => t.Id == d.element_id);
        const bolim = allData.olchov_turlari.find((t) => t.Id == d.olchov_id);
        summa_total_som +=
          d.valyuta_turi == 2 ? d.sotish_narxi * d.miqdor_soni : 0;
        summa_total_val +=
          d.valyuta_turi == 1 ? d.sotish_narxi * d.miqdor_soni : 0;
        total_miqdor += d.miqdor_soni * 1;
        body.push({
          data: [
            {
              value: index + 1,
              styles: { borderLeft: "1px solid #000", width: "50px" },
            },
            {
              value: tovar ? tovar.nomi : null,
              styles: {
                textAlign: "left",
                width: "300px",
                fontFamily: "Arial, Helvetica, sans-serif",
              },
            },
            {
              value: bolim ? bolim.nomi : null,
              styles: {
                width: "70px",
                textAlign: "left",
                fontFamily: "Arial, Helvetica, sans-serif",
              },
            },
            {
              value: d.miqdor_soni,
              styles: {
                width: "100px",
                fontFamily: "Arial, Helvetica, sans-serif",
              },
            },
            {
              value: NumberFormat(d.valyuta_turi == 2 ? d.sotish_narxi : "", 2),
              styles: {
                width: "100px",
                fontFamily: "Arial, Helvetica, sans-serif",
              },
            },
            {
              value: NumberFormat(d.valyuta_turi == 1 ? d.sotish_narxi : "", 1),
              styles: {
                width: "100px",
                fontFamily: "Arial, Helvetica, sans-serif",
              },
            },
            {
              value: NumberFormat(
                d.valyuta_turi == 2 ? d.sotish_narxi * d.miqdor_soni : "",
                2
              ),
              styles: {
                width: "100px",
                fontFamily: "Arial, Helvetica, sans-serif",
              },
            },
            {
              value: NumberFormat(
                d.valyuta_turi == 1 ? d.sotish_narxi * d.miqdor_soni : "",
                1
              ),
              styles: {
                width: "100px",
                fontFamily: "Arial, Helvetica, sans-serif",
              },
            },
            { styles: { border: "1px solid #fff" } },
          ],
        });
      });
      body.push({
        data: [
          {
            value: "Жами",
            cols: 5,
            styles: {
              textAlign: "right",
              fontWeight: 550,
              borderLeft: "1px solid #000",
            },
          },
          {
            value: "",
          },
          {
            value: NumberFormat(summa_total_som, 2),
            styles: {
              textAlign: "right",
              fontWeight: 550,
              borderLeft: "1px solid #000",
            },
          },
          {
            value: NumberFormat(summa_total_val, 1),
            styles: {
              textAlign: "right",
              fontWeight: 550,
              borderLeft: "1px solid #000",
            },
          },
          {
            value: NumberFormat(d.qarzi_som * 1 + summa_total_som * 1, 2),
            styles: {
              borderRight: "1px solid #fff",
              borderBottom: "1px solid #fff",
              color: "blue",
              fontSize: "13px",
            },
          },
          {
            value: NumberFormat(d.qarzi_val * 1 + summa_total_val * 1, 1),
            styles: {
              borderRight: "1px solid #fff",
              borderBottom: "1px solid #fff",
              borderTop: "1px solid #fff",
              color: "blue",
              fontSize: "13px",
            },
          },
        ],
      });

      all_miqdor += total_miqdor;
      all_summa_som += summa_total_som;
      all_summa_val += summa_total_val;
      all_qarzi_som += d.qarzi_som * 1 + summa_total_som * 1;
      all_qarzi_val += d.qarzi_val * 1 + summa_total_val * 1;
    }
  });

  // summary
  if (summary) {
    body.push({
      br: true,
      data: [
        {
          value: "Жами",
          cols: 3,
          styles: {
            textAlign: "right",
            fontWeight: 550,
            borderLeft: "1px solid #000",
          },
        },

        {
          value: all_miqdor,
          cols: 1,
          styles: {
            textAlign: "right",
            fontWeight: 550,
            borderLeft: "1px solid #000",
          },
        },
        {
          value: "",
          cols: 2,
        },
        {
          value: NumberFormat(all_summa_som, 2),
          styles: {
            textAlign: "center",
            fontWeight: 550,
            borderLeft: "1px solid #000",
          },
        },
        {
          value: NumberFormat(all_summa_val, 2),
          styles: {
            textAlign: "center",
            fontWeight: 550,
            borderLeft: "1px solid #000",
          },
        },
        {
          value: NumberFormat(all_qarzi_som, 2),
          styles: { borderRight: "1px solid #000" },
        },
        {
          value: NumberFormat(all_qarzi_val, 2),
          styles: { borderRight: "1px solid #000" },
        },
      ],
    });
  }

  return body;
};
