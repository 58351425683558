import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import NumberFormat from "../../Functions/NumberFormat";
import "./print.css";
import PrintTable from "./PrintTable";
import { useHistory } from "react-router";
import loginBrand from "../../images/Logo.png";
import { RiArrowGoBackFill } from "react-icons/ri";
import InternalPreviewGroup from "antd/lib/image/PreviewGroup";
import PrintBeautyTable from "./PrintBeautyTable";
const BeautyContainer = ({ element, allData }) => {
  const history = useHistory();
  const [info, setInfo] = useState({});
  useEffect(() => {
    const obj = element;
    let dolg_som_after = 0;
    let dolg_val_after = 0;

    obj?.document?.forEach((d) => {
      dolg_som_after +=
        d.valyuta_turi == 2 ? d.miqdor_soni * 1 * d.sotish_narxi * 1 : 0;
      dolg_val_after +=
        d.valyuta_turi == 1 ? d.miqdor_soni * 1 * d.sotish_narxi * 1 : 0;
    });

    const mij = allData.mijozlar.find((m) => m.Id == obj.mijoz_id)
      ? allData.mijozlar.find((m) => m.Id == obj.mijoz_id)
      : {};
    const agent = allData.agentlar.find((m) => m.Id == obj.agent_id)
      ? allData.agentlar.find((m) => m.Id == obj.agent_id)
      : {};
    const newObj = {
      qarzi_som: obj.qarzi_som,
      qarzi_val: obj.qarzi_val,
      dolg_som_after,
      dolg_val_after,
      time: obj.time_update,
      mijoz_fio: mij.fio,
      mijoz_telefon: mij.telefon,
      agent_fio: agent.fio,
      agent_telefon: agent.telefon,
      Id: obj.Id,
    };
    setInfo(newObj);
  }, [element]);

  return (
    <div className="print_container">
      {/* <div>
        <RiArrowGoBackFill
          onClick={() => history.goBack()}
          style={{ color: "blue", cursor: "pointer" }}
        ></RiArrowGoBackFill>
      </div> */}

      <div className="print_body_container">
        <span className="print_user" style={{ display: "flex" }}>
          <p
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div>
              ID: <span>{info.Id}</span>
            </div>
            <div>
              Мижоз:
              <span> {info.mijoz_fio}</span>
              <span>_ _ _ +{info.mijoz_telefon}</span>
            </div>
            <div>
              Сана:{" "}
              <span>{moment(info.time).format("DD.MM.YYYY HH:mm:ss")}</span>
            </div>
          </p>
          <div className="print_header" style={{ width: "200px" }}>
            <img height="50" src={loginBrand} alt="logo" />
          </div>
          <p
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              alignItems: "flex-end",
              width: "100%",
            }}
          >
            <div>
              Агент:
              <span> {info.agent_fio}</span>
            </div>
            <div>Долг сўм: {NumberFormat(info.qarzi_som)}</div>
            <div>Долг вал: {NumberFormat(info.qarzi_val, 1)}</div>
          </p>
        </span>
      </div>
      <PrintBeautyTable element={element}></PrintBeautyTable>
      <h4
        style={{
          textAlign: "right",
          margin: 0,
          marginRight: "30px",
          fontWeight: "700",
        }}
      >
        Долг сўм: {NumberFormat(info.qarzi_som * 1 + info.dolg_som_after * 1)}
        <br />
        Долг вал:{" "}
        {NumberFormat(info.qarzi_val * 1 + info.dolg_val_after * 1, 1)}
      </h4>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "20px",
          marginBottom: "15px",
        }}
      >
        <h4 style={{ fontWeight: "700" }}>
          Доставщик _____________________________________________
        </h4>
        <h4 style={{ fontWeight: "700" }}>
          Мижоз _____________________________________________
        </h4>
      </div>
      <hr class="rounded" color="red"></hr>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    rowId: state.app.rowId,
    mainTableData: state.app.mainTableData,
    allData: state.app.allData,
  };
};

export default connect(mapStateToProps)(BeautyContainer);
