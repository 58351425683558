import {
  SELECT,
  STRING,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import { RiBuildingFill } from "react-icons/ri";
import { SIMPLE } from "../../Component/Sidebar/PageTypes";
const Shahar = {
  title: "Шахар / Туман",
  path: "/servis/shaharTuman",
  icon: <RiBuildingFill></RiBuildingFill>,
  type: SIMPLE,
  columns: [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">ID</div>,
      dataIndex: "Id",
      width: "100px",
      align: "center",
    },
    {
      title: <div className="table_head">Номи</div>,
      dataIndex: "nomi",
      key: "nomi",
    },
    {
      title: <div className="table_head">Вилоят</div>,
      dataIndex: "viloyatlar",
      key: "viloyatlar",
    },
  ],

  allData: [{ value: "viloyat_id", prop: "viloyatlar", get: "nomi" }],
  forms: [
    {
      grid: "2fr 1fr",
      inputs: [
        {
          label: "Номи",
          name: "nomi",
          type: STRING,
          required: true,
        },
        {
          label: "Вилоят",
          name: "viloyat_id",
          type: SELECT,
          required: true,
          allData: {
            type: "viloyatlar",
            key: "Id",
            text: "nomi",
          },
        },
      ],
    },
  ],
};

export default Shahar;
