import React from "react";
import { connect } from "react-redux";
import { setValues, setValues2 } from "../../Actions/AppActions";
import ModalInput from "./ModalComponents/ModalInput";

const ModalInputs = ({
  forms,
  setValues,
  values,
  secondaryModal,
  setValues2,
}) => {
  const handleChange = e => {
    if (secondaryModal.open) {
      setValues2({
        ...values,
        [e.target.name]: e.target.value,
      });
    } else {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      });
    }
  };
  return (
    <>
      {forms?.map(f => (
        <div className="grid_form" style={{ gridTemplateColumns: f.grid }}>
          {f.inputs.map(inp => (
            <ModalInput
              type={inp.type}
              label={inp.label}
              name={inp.name}
              component={inp.component}
              allDataType={inp.allData}
              onChange={handleChange}
              readOnly={inp.readOnly}
              secondDate={inp.secondDate}
              condition={inp.condition}
              costumData={inp.costumData}
              costumColumns={inp.costumColumns}
            ></ModalInput>
          ))}
        </div>
      ))}
    </>
  );
};
const mapStateToProps = state => {
  return {
    values: state.app.secondaryModal.open
      ? state.app.values2
      : state.app.values,
    secondaryModal: state.app.secondaryModal,
  };
};
export default connect(mapStateToProps, { setValues, setValues2 })(ModalInputs);
