import { RiFileList3Fill } from "react-icons/ri";
import {
  COSTUM,
  DATE,
  SELECT,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import { HISOBOT } from "../../Component/Sidebar/PageTypes";
import PrintBtn from "./components/PrintBtn";
import Refresh from "./components/Refresh";
const MijozPM = {
  title: "Реестр мижоз",
  path: "/hisobot/plus-minus-mijoz",
  icon: <RiFileList3Fill></RiFileList3Fill>,
  type: HISOBOT,
  allData: [{ value: "mijoz_id", prop: "mijozlar", get: "fio" }],
  hisobotActions: [
    {
      grid: "1fr 1fr 1fr 1fr",
      inputs: [
        {
          label: "Сана",
          name: "sana1",
          type: DATE,
        },
        {
          label: "Сана 2",
          name: "sana2",
          type: DATE,
          secondDate: true,
        },
        {
          label: "Мижозлар",
          name: "mijoz_id",
          type: SELECT,
          allData: {
            type: "mijozlar",
            key: "Id",
            text: "fio",
          },
        },
        {
          type: COSTUM,
          component: (
            <div
              style={{ display: "flex", gridGap: "10px", alignItems: "center" }}
            >
              <Refresh text="Янгилаш"></Refresh>
              <PrintBtn></PrintBtn>
            </div>
          ),
        },
      ],
    },
  ],
};
export default MijozPM;
