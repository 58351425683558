import DTKTkontragentov from "./DTKTKontragent";
import KassaHisobot from "./KassaHisobot";
import MaxsulotHisoboti from "./MaxsulotHisoboti";
import MaxsulotOborot from "./MaxsulotOborot";
import MijozAktsverka from "./MijozAktsverkasi";
import MijozlarQoldigi from "./MijozlarQoldigi";
import MijozPM from "./MijozPM";
import OstatkaPM from "./OstatkaPM";
import Pribl from "./Pribl";
import YukAktsverkaHisobot from "./YukAktsverkaHisobot";
import YukBeruvchilarQoldigi from "./YukBeruvchilarQoldigi";
import YukPM from "./YukPM";

export default () => {
  return [
    MaxsulotHisoboti,
    MaxsulotOborot,
    OstatkaPM,
    MijozAktsverka,
    MijozlarQoldigi,
    MijozPM,
    YukAktsverkaHisobot,
    YukBeruvchilarQoldigi,
    YukPM,
    KassaHisobot,
    DTKTkontragentov,
    Pribl,
  ];
};
