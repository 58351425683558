import { RiFileList3Fill } from "react-icons/ri";
import {
  COSTUM,
  DATE,
  SELECT,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import { HISOBOT } from "../../Component/Sidebar/PageTypes";
import Checkbox_his from "./components/Checkbox_his";
import PrintBtn from "./components/PrintBtn";
import Refresh from "./components/Refresh";
const DTKTkontragentov = {
  title: "Мижозлар қарздорликлари",
  path: "/hisobot/dtkt-kontragent",
  icon: <RiFileList3Fill></RiFileList3Fill>,
  type: HISOBOT,
  allData: [
    { value: "mijoz_id", prop: "mijozlar", get: "fio" },
    { value: "agent_id", prop: "agentlar", get: "fio" },
  ],
  hisobotActions: [
    {
      grid: "1fr 1fr 1fr 1fr 1fr",
      inputs: [
        {
          label: "Сана",
          name: "sana1",
          type: DATE,
        },
        {
          label: "Сана",
          name: "sana2",
          type: DATE,
          secondDate: true,
        },
        {
          label: "Агент",
          name: "agent_id",
          type: SELECT,
          allData: {
            type: "agentlar",
            key: "Id",
            text: "fio",
          },
        },
        {
          label: "Мижоз",
          name: "mijoz_id",
          type: SELECT,
          allData: {
            type: "mijozlar",
            key: "Id",
            text: "fio",
          },
        },
        {
          type: COSTUM,
          component: (
            <div
              style={{ display: "flex", gridGap: "10px", alignItems: "center" }}
            >
              <Checkbox_his text="Мижоз" name="mijozlar"></Checkbox_his>
              <Refresh group={true} text="Оборот"></Refresh>
              <Refresh text="Қолдиқ"></Refresh>
              <PrintBtn></PrintBtn>
            </div>
          ),
        },
      ],
    },
  ],
};
export default DTKTkontragentov;
