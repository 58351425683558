import React from "react";
import { RiEditCircleFill } from "react-icons/ri";
import { connect } from "react-redux";
import { setSecondaryModal, setValues2 } from "../../../Actions/AppActions";
import Maxsulot from "../../ServicePages/Maxsulot";

const ChangeMaxsulot = ({ record, setSecondaryModal, setValues2 }) => {
  const handleClick = () => {
    const obj = {
      ...record,
      Id: record.element_id,
    };
    delete obj.element_id;
    delete obj.miqdor;
    setValues2(obj);
    setSecondaryModal({
      open: true,
      path: Maxsulot.path,
      forms: Maxsulot.forms,
      title: Maxsulot.title,
    });
  };
  return (
    <RiEditCircleFill
      onClick={handleClick}
      style={{ color: "var(--main-color)", fontSize: "18px" }}
    ></RiEditCircleFill>
  );
};

export default connect(null, { setSecondaryModal, setValues2 })(ChangeMaxsulot);
