import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import SecondTable from "../SecondTable";
import ModalTableActions from "./ModalTableActions";

const ModalTable = ({
  columns,
  document,
  innerTable,
  innerTableData,
  stateDetector,
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (innerTable) {
      setData([...innerTableData]);
    } else {
      setData([...document]);
    }
  }, [stateDetector, innerTableData, document]);

  return (
    <div>
      {innerTable ? null : <ModalTableActions></ModalTableActions>}
      <SecondTable
        innerTable={innerTable}
        costumColumns={columns}
        data={data}
        notPag={true}
      ></SecondTable>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    document: state.app.values.document ? state.app.values.document : [],
    innerModal: state.app.innerModal,
    stateDetector: state.app.stateDetector,
  };
};
export default connect(mapStateToProps)(ModalTable);
