import { Button, message } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { RiPrinterFill } from "react-icons/ri";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  changeAllDocument,
  getInnerData,
  getMainTableData,
  setLoading,
  setMainTableData,
  setValues,
} from "../../Actions/AppActions";
import fetchApi from "../../Functions/fetchApi";
import FetchApi from "../../Functions/fetchApi";
import ModalInputs from "./ModalInputs";

const ModalForm = ({
  values,
  clearModal,
  setLoading,
  modal,
  getMainTableData,
  currentPage,
  setValues,
  allData,
  secondaryModal,
  changeAllDocument,
  rowId,
  actualValues,
  getInnerData,
}) => {
  useEffect(() => {
    if (
      !rowId &&
      values.yuk_beruvchi_id &&
      (currentPage.path == "/maxsulot-kirimi" ||
        currentPage.path == "/yuk-beruvchi-vozvrat")
    ) {
      fetchYukQarzi({
        xodim_id: values.yuk_beruvchi_id,
        sana: values.sana
          ? values.sana
          : moment().format("YYYY-MM-DD HH:mm:ss"),
      });
    }
  }, [values?.yuk_beruvchi_id]);

  useEffect(() => {
    if (values.xodim_id && currentPage.path == "/yuk-beruvchi-tolov") {
      fetchYukQarzi({
        xodim_id: values.xodim_id,
        sana: values.sana
          ? values.sana
          : moment().format("YYYY-MM-DD HH:mm:ss"),
      });
    }
  }, [values?.xodim_id]);

  useEffect(() => {
    if (!rowId && values.agent_id && values.mijoz_id) {
      fetchAgentQarzi({
        agent_id: values.agent_id,
        mijoz_id: values.mijoz_id,
        sana: values.sana
          ? values.sana
          : moment().format("YYYY-MM-DD HH:mm:ss"),
      });
    }
  }, [values?.agent_id, values?.mijoz_id, values?.sana]);

  useEffect(() => {
    if (currentPage.path == "/mijoz-buyurtma") {
      const docs = values.document;
      let mapedDocs = [];
      if (allData.ostatka) {
        mapedDocs = docs?.map((d) => {
          let ost = allData.ostatka.find((a) => a.Id == d.element_id);
          if (ost) {
            return { ...d, qoldiq: ost.qoldiq };
          } else {
            return d;
          }
        });
        changeAllDocument(mapedDocs);
      }
    }
  }, [modal]);

  const fetchYukQarzi = async (obj) => {
    setLoading(true);
    const res = await fetchApi("/yukberuvchi_qarzi", "POST", obj);

    if (res && res.status == 200) {
      setValues({
        ...values,
        qarzi_som: res.data.length > 0 ? res.data[0].summa_som : 0,
        qarzi_val: res.data.length > 0 ? res.data[0].summa_val : 0,
      });
    }

    setLoading(false);
  };

  const fetchAgentQarzi = async (data) => {
    setLoading(true);
    const res = await fetchApi("/mijozqarzi", "POST", data);
    if (res && res.status == 200) {
      setValues({
        ...values,
        qarzi_som: res.data.length > 0 ? res.data[0].summa_som : 0,
        qarzi_val: res.data.length > 0 ? res.data[0].summa_val : 0,
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (modal && currentPage.innerData) {
      getInnerData([currentPage.innerData]);
    }
  }, [modal]);
  const { location } = useHistory();
  const history = useHistory();
  const [loadings, setLoadings] = useState(false);
  const key = 32432;
  const handleSubmit = () => {
    const requiredInputs = [];
    currentPage.forms.forEach((el) => {
      el.inputs.forEach((d) => {
        if (d.required) {
          requiredInputs.push(d);
        }
      });
    });
    let error = [];
    requiredInputs.forEach((d) => {
      if (!(values[d.name] && values[d.name] !== "")) {
        error.push(`${d.label} kiriting!`);
      }
    });
    if (error.length > 0) {
      message.error({ content: error[0], key: key });
    } else {
      RequestJson(
        secondaryModal.open ? secondaryModal.path : location.pathname,
        values,
        getMainTableData
      );
    }
  };

  const elementChangeDocument = () => {
    const document = actualValues.document;
    let newDocument = [];
    if (document) {
      newDocument = document.map((doc) => {
        if (doc.Id == rowId) {
          return {
            ...doc,
            ...values,
            Id: rowId,
            element_id: values.Id,
          };
        } else return doc;
      });

      changeAllDocument(newDocument);
    }
  };

  const RequestJson = async (url, data, refreshCallback) => {
    let user_id = null;
    if (
      JSON.parse(window.localStorage.getItem("user")) &&
      JSON.parse(window.localStorage.getItem("user")).Id
    ) {
      user_id = JSON.parse(window.localStorage.getItem("user")).Id;
    }
    setLoadings(true);
    const response = await FetchApi(url, "POST", { ...data, user_id: user_id });
    if (response && response.status === 200) {
      refreshCallback(url);
      elementChangeDocument();
      clearModal();
    }
    setLoadings(false);
  };

  const mijozBuyurtma = () => {
    if (currentPage.path == "/agent-buyurtma") {
      RequestJson("/agent-sotuv", values, getMainTableData);
    } else {
      RequestJson("/mijoz-sotuv", values, getMainTableData);
    }
  };
  const agentTolov = () => {
    let error = "";
    values.document.forEach((d) => {
      if (!d.agent_id || !d.tolov_turi) {
        error = "Формани тўлдиринг!";
      }
    });
    if (error) {
      message.error({ content: error, key: key });
    } else {
      RequestJson("/agent-tolov-accept", values, getMainTableData);
    }
  };
  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="form_container">
          {
            <ModalInputs
              forms={
                secondaryModal.open ? secondaryModal.forms : currentPage.forms
              }
            >
              {" "}
            </ModalInputs>
          }
        </div>
      </form>
      <div className="form_submit">
        <div
          style={{ display: "flex", justifyContent: "center", gridGap: "10px" }}
        >
          <Button className={"form-btn btn-back"} onClick={() => clearModal()}>
            Ортга
          </Button>
          <Button
            loading={loadings}
            className={"form-btn btn-submit"}
            onClick={handleSubmit}
          >
            Сақлаш
          </Button>
          {(currentPage.path == "/agent-buyurtma" ||
            currentPage.path == "/mijoz-buyurtma") && (
            <Button
              onClick={mijozBuyurtma}
              type="primary"
              danger
              className="form-btn"
            >
              Буюртмани қабул қилиш
            </Button>
          )}
          {currentPage.path == "/agent-tolov" && (
            <Button
              onClick={agentTolov}
              type="primary"
              danger
              className="form-btn"
            >
              Тўловларни қабул қилиш
            </Button>
          )}
          {currentPage.path == "/sotuv-bolimi" && (
            <Button
              onClick={() => history.push("/print")}
              type="primary"
              className="action_btn main-btn"
            >
              <RiPrinterFill className="action_icon"></RiPrinterFill>
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    values: state.app.secondaryModal.open
      ? state.app.values2
      : state.app.values,
    modal: state.app.modal,
    actualValues: state.app.values,
    mainTableData: state.app.mainTableData,
    rowId: state.app.rowId,
    currentPage: state.app.currentPage,
    allData: state.app.allData,
    secondaryModal: state.app.secondaryModal,
  };
};

export default connect(mapStateToProps, {
  setValues,
  setMainTableData,
  getMainTableData,
  setLoading,
  setValues,
  changeAllDocument,
  getInnerData,
})(ModalForm);
