import {
  IMAGE,
  MODALTABLE,
  SELECT,
  STRING,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import { RiVipCrownFill } from "react-icons/ri";
import { SIMPLE } from "../../Component/Sidebar/PageTypes";
import { BaseUrl } from "../../BaseUrl";
import Zoom from "react-medium-image-zoom";
const Brendlar = {
  title: "Брендлар",
  path: "/servis/brendlar",
  icon: <RiVipCrownFill></RiVipCrownFill>,
  type: SIMPLE,
  innerData: "tovarlar",
  innerAll: [{ value: "brend_id", prop: "brendlar", get: "nomi" }],
  defaultDocument: {
    allData: "tovarlar",
    prop: "brend_id",
  },
  columns: [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">ID</div>,
      dataIndex: "Id",
      width: "100px",
      align: "center",
    },
    {
      title: <div className="table_head">Номи</div>,
      dataIndex: "nomi",
      key: "nomi",
      width: "132px",
    },
    // {
    //   title: <div className="table_head">Бўлим</div>,
    //   dataIndex: "bolimlar",
    //   key: "bolimlar",
    //   // onFilter: (value, record) => record.bolimlar?.indexOf(value) === 0,
    //   width: "137px",
    // },
    {
      title: <div className="table_head">Юк берувчи</div>,
      dataIndex: "yuk_beruvchilar",
      key: "yuk_beruvchilar",
    },
    // {
    //   title: <div className="table_head">Расм</div>,
    //   dataIndex: "img",
    //   key: "img",
    //   render: text => {
    //     if (text) {
    //       return (
    //         <Zoom zoomMargin={40}>
    //           <picture>
    //             <source
    //               media="max-width: 200px"
    //               srcSet={BaseUrl + text.slice(1)}
    //             />
    //             <img src={BaseUrl + text.slice(1)} alt="asda" height={30} />
    //           </picture>
    //         </Zoom>
    //       );
    //     } else return "";
    //   },
    // },
  ],
  allData: [
    { value: "yuk_beruvchi_id", prop: "yuk_beruvchilar", get: "fio" },
    { value: "maxsulot_id", prop: "tovarlar", get: "nomi" },
    { value: "brend_id", prop: "brendlar", get: "nomi" },
    { value: "bolim_id", prop: "bolimlar", get: "nomi" },
  ],
  forms: [
    {
      grid: "1fr 1fr",
      inputs: [
        {
          label: "Номи",
          name: "nomi",
          type: STRING,
          required: true,
        },
        // {
        //   label: "Расм",
        //   name: "img",
        //   type: IMAGE,
        // },
        // {
        //   label: "Бўлим",
        //   name: "bolim_id",
        //   type: SELECT,
        //   required: true,
        //   allData: {
        //     type: "bolimlar",
        //     key: "Id",
        //     text: "nomi",
        //   },
        // },
        {
          label: "Юк берувчи",
          name: "yuk_beruvchi_id",
          type: SELECT,
          required: true,
          allData: {
            type: "yuk_beruvchilar",
            key: "Id",
            text: "fio",
          },
        },
      ],
    },
    {
      type: "document",
      grid: "1fr",
      inputs: [
        {
          label: "Махсулотлар",
          name: "document",
          type: MODALTABLE,
          innerModal: {
            title: "Махсулот",
            // unique: true,
            allData: "tovarlar",
            columns: [
              {
                title: <div className="table_head">№</div>,
                key: "index",
                render: (text, record, index) => index + 1,
                width: "50px",
                align: "center",
              },
              {
                title: <div className="table_head">Махсулот номи</div>,
                dataIndex: "nomi",
                key: "nomi",
                width: "400px",
              },
              {
                title: <div className="table_head">Бренд</div>,
                dataIndex: "brendlar",
                key: "brendlar",
                onFilter: (value, record) =>
                  record.brendlar?.indexOf(value) === 0,
              },
            ],
          },

          costumColumns: [
            {
              title: <div className="table_head">№</div>,
              key: "index",
              render: (text, record, index) => index + 1,
              width: "50px",
              align: "center",
            },
            {
              title: <div className="table_head">Махсулот номи</div>,
              dataIndex: "nomi",
              key: "nomi",
            },
          ],
        },
      ],
    },
  ],
};
export default Brendlar;
