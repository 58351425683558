import React from "react";
import { Checkbox } from "antd";
import { setValues } from "../../../Actions/AppActions";
import { connect } from "react-redux";
const Checkbox_his = ({ text, name, values, setValues }) => {
  const onChange = e => {
    setValues({
      ...values,
      [name]: e.target.checked,
    });
  };
  return <Checkbox onChange={onChange}>{text}</Checkbox>;
};
const mapStateToProps = state => {
  return {
    values: state.app.values,
  };
};
export default connect(mapStateToProps, { setValues })(Checkbox_his);
