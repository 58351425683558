import MiqdorInput from "../../Component/Tables/ModalTable/KirimChiqimInputs/MiqdorInput";
import { RiStackFill } from "react-icons/ri";
import {
  DATE,
  MODALTABLE,
  STRING,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import { SIMPLE } from "../../Component/Sidebar/PageTypes";
import MiqdorSelect from "../../Component/Tables/ModalTable/KirimChiqimInputs/MiqdorSelect";
import DocumentClass from "../../Functions/DocumentClass";
import NumberFormat from "../../Functions/NumberFormat";
import IzohInput from "../../Component/Tables/ModalTable/KirimChiqimInputs/IzohInput";
import SanaFormat from "../../Functions/SanaFormat";
import ShowAllBtn from "../Savdo/Components/ShowAllBtn";
const AgentTolov = {
  title: "Агент тўловлари",
  path: "/agent-tolov",
  type: SIMPLE,
  icon: <RiStackFill></RiStackFill>,
  allData: [
    { value: "mijoz_id", prop: "mijozlar", get: "fio" },
    { value: "agent_id", prop: "agentlar", get: "fio" },
    { value: "tolov_turi", prop: "tolov_turlari", get: "nomi" },
  ],
  modalJami: "agent-tolov",
  innerData: "mijozlar",
  columns: [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">ID</div>,
      dataIndex: "Id",
      width: "100px",
      align: "center",
    },
    {
      title: <div className="table_head">Сана</div>,
      dataIndex: "time_create",
      key: "nomi",
      width: "150px",
      render: (text) => SanaFormat(text),
    },
    {
      title: <div className="table_head">Жами сумма</div>,
      dataIndex: "document",
      align: "right",
      key: "nomi",
      width: "150px",
      render: (text) =>
        NumberFormat(DocumentClass(text, "agent-tolov").summa_som),
    },
    {
      title: <div className="table_head">Жами сумма переч</div>,
      dataIndex: "document",
      key: "nomi",
      width: "150px",
      align: "right",
      render: (text) =>
        NumberFormat(DocumentClass(text, "agent-tolov").summa_perech),
    },
    {
      title: <div className="table_head">Жами сумма вал</div>,
      dataIndex: "document",
      key: "nomi",
      width: "150px",
      align: "right",
      render: (text) =>
        NumberFormat(DocumentClass(text, "agent-tolov").summa_val, 1) + " $",
    },
    {
      title: <div className="table_head">Изох</div>,
      dataIndex: "izoh",
      key: "nomi",
      width: "178px",
    },
  ],
  forms: [
    {
      type: "document",
      grid: "1fr",
      actionInputs: [
        {
          grid: "2fr 4fr",
          inputs: [
            {
              label: "Сана",
              name: "time_create",
              type: DATE,
            },
            {
              label: "Изох",
              name: "izoh",
              type: STRING,
            },
          ],
        },
      ],
      inputs: [
        {
          name: "document",
          type: MODALTABLE,
          innerModal: {
            title: "Мижозлар",
            columns: [
              {
                title: <div className="table_head">№</div>,
                key: "index",
                render: (text, record, index) => index + 1,
                width: "50px",
                align: "center",
              },
              {
                title: <div className="table_head">Номи</div>,
                dataIndex: "fio",
                key: "nomi",
              },
            ],
          },
          costumColumns: [
            {
              title: <div className="table_head">№</div>,
              key: "index",
              render: (text, record, index) => index + 1,
              width: "40px",
              align: "center",
            },
            {
              title: <div className="table_head">Мижоз</div>,
              dataIndex: "element_id",
              key: "nomi",
              width: "250px",
              render: (text) => (
                <MiqdorSelect
                  name={"element_id"}
                  value={text}
                  placeholder="Мижоз"
                  cursor="1"
                  get="fio"
                  allDataType={"mijozlar"}
                ></MiqdorSelect>
              ),
            },
            {
              title: <div className="table_head">Агент</div>,
              dataIndex: "agent_id",
              key: "nomi",
              width: "200px",
              render: (text) => (
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <MiqdorSelect
                    name={"agent_id"}
                    value={text}
                    placeholder="Агент"
                    get="fio"
                    allDataType={"agentlar"}
                  ></MiqdorSelect>
                  <ShowAllBtn></ShowAllBtn>
                </div>
              ),
            },
            {
              title: <div className="table_head">Қарзи</div>,
              dataIndex: "qarzi_som",
              key: "nomi",
              width: "100px",
              render: (text) => NumberFormat(text, 2),
            },
            {
              title: <div className="table_head">Қарзи вал</div>,
              dataIndex: "qarzi_val",
              key: "nomi",
              width: "100px",
              render: (text) => NumberFormat(text, 1),
            },
            {
              title: <div className="table_head">Тўлов тури</div>,
              dataIndex: "tolov_turi",
              key: "nomi",
              width: "110px",
              render: (text) => (
                <MiqdorSelect
                  name={"tolov_turi"}
                  value={text}
                  get="nomi"
                  placeholder="Тўлов тури"
                  allDataType={"tolov_turlari"}
                ></MiqdorSelect>
              ),
            },
            {
              title: <div className="table_head">Сумма</div>,
              dataIndex: "summa_som",
              key: "nomi",
              render: (text) => (
                <MiqdorInput name="summa_som" value={text}></MiqdorInput>
              ),
              width: "150px",
            },
            {
              title: <div className="table_head">Изох</div>,
              dataIndex: "izoh",
              render: (text) => (
                <IzohInput name="izoh" value={text}></IzohInput>
              ),
            },
            {
              title: <div className="table_head">Сана</div>,
              dataIndex: "time_create",
              width: "95px",
              render: (text) => SanaFormat(text),
            },
          ],
        },
      ],
    },
  ],
};

export default AgentTolov;
