import React from "react";
import MainTable from "../../Tables/MainTable";
import Actions from "../../Actions/Actions";
const SimplePage = ({ searchDataSource }) => {
  return (
    <>
      <Actions></Actions>
      <MainTable costumColumns={false} data={searchDataSource}></MainTable>
    </>
  );
};

export default SimplePage;
