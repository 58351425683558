import {
  OPEN_SIDE,
  SET_LOADING,
  SET_MAIN_TABLE_DATA,
  SET_ROW_ID,
  SET_VALUES,
  GET_ALL_DATA,
  DOCUMENT_CHANGE,
  SET_MODAL,
  SET_CURRENT_PAGE,
  DELETE_LINKS,
  SET_FILE,
  SET_INNER_MODAL,
  ADD_DOCUMENT,
  DELETE_DOCUMENT,
  CHANGE_ALL_DOCUMENT,
  GET_INNER_DATA,
  SET_MODAL2,
  SET_SECONDARY_MODAL,
  GET_HISOBOT,
  CHANGE_DOCUMENT_OBJ,
  STATE_CHANGE_DETECT,
} from "./AppTypes";
import FetchApi from "../Functions/fetchApi";
import { message } from "antd";

function jsonChecker(x) {
  try {
    return JSON.parse(x);
  } catch (e) {
    return x;
  }
}

export const getMainTableData = (url) => async (dispatch) => {
  dispatch({
    type: SET_LOADING,
    payload: true,
  });

  let response;
  response = await FetchApi(url, "get");

  if (response && response.status === 200) {
    dispatch({
      type: SET_MAIN_TABLE_DATA,
      payload: response.data.map((d) => {
        if (d.hasOwnProperty("document")) {
          return { ...d, document: jsonChecker(d.document) };
        } else {
          return d;
        }
      }),
    });
  }

  dispatch({
    type: SET_LOADING,
    payload: false,
  });
};

export const getHisobot = (url, data) => async (dispatch) => {
  dispatch({
    type: SET_LOADING,
    payload: true,
  });
  let response;
  if (data) {
    response = await FetchApi(url, "POST", data);
  } else {
    response = await FetchApi(url, "POST");
  }

  if (response && response.status === 200) {
    console.log(response.data);
    dispatch({
      type: GET_HISOBOT,
      payload:
        url == "/hisobot/naklad" || url == "/hisobot/restrTolov"
          ? response.data.map((d) => {
              if (d.hasOwnProperty("document")) {
                return { ...d, document: jsonChecker(d.document) };
              } else {
                return d;
              }
            })
          : response.data,
    });
  }

  dispatch({
    type: SET_LOADING,
    payload: false,
  });
};

export const setHisobot = (data) => (dispatch) => {
  dispatch({
    type: GET_HISOBOT,
    payload: data,
  });
};

export const getAllData = (list) => async (dispatch) => {
  dispatch({
    type: SET_LOADING,
    payload: true,
  });

  const data = await FetchApi("/alldata2", "POST", list);
  if (data && data.status === 200) {
    dispatch({
      type: GET_ALL_DATA,
      payload: data.data,
    });
  }

  dispatch({
    type: SET_LOADING,
    payload: false,
  });
};

export const getLogin = (data) => async (dispatch) => {
  dispatch({
    type: SET_LOADING,
    payload: true,
  });
  const response = await FetchApi("/login", "POST", data);
  if (response && response.data.length > 0) {
    window.localStorage.setItem("user", JSON.stringify(response.data[0]));

    window.location.href = "/";
  } else {
    message.error("Login yoki parol xato!");
  }

  dispatch({
    type: SET_LOADING,
    payload: false,
  });
};

export const setMainTableData = (data) => (dispatch) => {
  dispatch({
    type: SET_MAIN_TABLE_DATA,
    payload: data,
  });
};

export const setRowId = (data) => (dispatch) => {
  dispatch({
    type: SET_ROW_ID,
    payload: data,
  });
};

export const openSide = (data) => (dispatch) => {
  dispatch({
    type: OPEN_SIDE,
    payload: data,
  });
};
export const stateDetector = () => (dispatch) => {
  dispatch({
    type: STATE_CHANGE_DETECT,
  });
};
export const changeDocumentObj = (data, rowId) => (dispatch) => {
  dispatch({
    type: CHANGE_DOCUMENT_OBJ,
    payload: {
      data: data,
      rowId: rowId,
    },
  });
};

export const setValues = (data) => (dispatch) => {
  dispatch({
    type: SET_VALUES,
    payload: data,
  });
};
export const setValues2 = (data) => (dispatch) => {
  dispatch({
    type: "SET_VALUES_2",
    payload: data,
  });
};
export const setDocument = (data) => (dispatch) => {
  dispatch({
    type: DOCUMENT_CHANGE,
    payload: data,
  });
};

export const setLoading = (data) => (dispatch) => {
  dispatch({
    type: SET_LOADING,
    payload: data,
  });
};
export const documentChange = (e) => (dispatch) => {
  dispatch({
    type: DOCUMENT_CHANGE,
    payload: e,
  });
};

export const setModal = (data) => (dispatch) => {
  dispatch({
    type: SET_MODAL,
    payload: data,
  });
};
export const setModal2 = (data) => (dispatch) => {
  dispatch({
    type: SET_MODAL2,
    payload: data,
  });
};
export const setSecondaryModal = (data) => (dispatch) => {
  dispatch({
    type: SET_SECONDARY_MODAL,
    payload: data,
  });
};
export const setFile = (data) => (dispatch) => {
  dispatch({
    type: SET_FILE,
    payload: data,
  });
};
export const setInnerModal = (data) => (dispatch) => {
  dispatch({
    type: SET_INNER_MODAL,
    payload: data,
  });
};
export const setCurrentPage = (data) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_PAGE,
    payload: data,
  });
};
export const deleteLinks = (data) => (dispatch) => {
  dispatch({
    type: DELETE_LINKS,
    payload: data,
  });
};

export const addDocument = (data) => (dispatch) => {
  dispatch({
    type: ADD_DOCUMENT,
    payload: data,
  });
};
export const deleteDocument = (data) => (dispatch) => {
  dispatch({
    type: DELETE_DOCUMENT,
    payload: data,
  });
};

export const changeAllDocument = (data) => (dispatch) => {
  dispatch({
    type: CHANGE_ALL_DOCUMENT,
    payload: data,
  });
};

export const getInnerData = (list) => async (dispatch) => {
  dispatch({
    type: SET_LOADING,
    payload: true,
  });

  const data = await FetchApi("/alldata2", "POST", list);
  if (data && data.status === 200) {
    dispatch({
      type: GET_INNER_DATA,
      payload: data.data,
    });
  }

  dispatch({
    type: SET_LOADING,
    payload: false,
  });
};

export const setInnerData = (data) => (dispatch) => {
  dispatch({
    type: GET_INNER_DATA,
    payload: data,
  });
};
