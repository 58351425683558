import React from "react";
import CurrencyInput from "react-currency-input-field";
import { connect } from "react-redux";
import { setDocument } from "../../../../Actions/AppActions";

const MiqdorInput = ({ setDocument, name, value }) => {
  return (
    <CurrencyInput
      value={value}
      decimalsLimit={2}
      decimalSeparator="."
      className="currency_input"
      name={name}
      onValueChange={(value, name) => {
        const obj = {
          target: {
            name: name,
            value: value,
          },
        };
        setDocument(obj);
      }}
    />
  );
};

export default connect(null, { setDocument })(MiqdorInput);
