import moment from "moment";
import React from "react";
import { connect } from "react-redux";
const TableGen = ({ table, values, title }) => {
  return (
    <>
      <h3
        style={{
          textAlign: "center",
          width: "450px",
          margin: "0 auto",
          fontWeight: 550,
        }}
      >
        {" "}
        {title}
        <br />
        <span
          style={{
            fontSize: "13px",
            fontFamily: "Arial, Helvetica, sans-serif",
          }}
        >
          {moment(values.sana).format("DD.MM.YYYY 00:00:01") +
            " - " +
            moment(values.sana2).format("DD.MM.YYYY 23:59:59")}
          {""}
        </span>
      </h3>
      <div className={"naklad-table"}>
        <table>
          <thead>
            {table.thead.map((th) => (
              <tr className={th.className}>
                {th.data.map((i) => (
                  <th
                    colSpan={i.cols ? i.cols : 1}
                    rowSpan={i.rows ? i.rows : 1}
                    style={i.styles ? i.styles : null}
                  >
                    {i.value ? i.value : ""}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.tbody.map((th) => (
              <>
                {th.br ? <br /> : null}
                <tr className={th.className}>
                  {th.data.map((i) => (
                    <td
                      colSpan={i.cols ? i.cols : 1}
                      rowSpan={i.rows ? i.rows : 1}
                      style={i.styles ? i.styles : null}
                    >
                      {i.value ? i.value : ""}
                    </td>
                  ))}
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default connect(({ app }) => ({ values: app.values }))(TableGen);
