import React from "react";
import HisobotPage from "./Pages/HisobotPage";
import ServicesPage from "./Pages/ServicesPage";
import SimplePage from "./Pages/SimplePage";
import { HISOBOT, SERVICES, SIMPLE } from "./PageTypes";

const Page = ({ type, dataSource }) => {
  let page = "";
  switch (type) {
    case SIMPLE:
      page = <SimplePage searchDataSource={dataSource}></SimplePage>;
      break;
    case SERVICES:
      page = <ServicesPage></ServicesPage>;
      break;
    case HISOBOT:
      page = <HisobotPage></HisobotPage>;
      break;
    default:
      break;
  }
  return page;
};

export default Page;
