// export default x => {
//   const y = parseInt(x)
//     .toString()
//     .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
//   return y;
// };

import numeral from "numeral";

export default (number, type) => {
  if (type && type == 1) {
    let a = "";
    if (number?.toString().length > 4) {
      a = numeral(number).format("0,0.00").replaceAll(",", " ");
    } else {
      a = number;
    }

    return a;
  } else {
    return numeral(number).format("0,0").replaceAll(",", " ");
  }
};
