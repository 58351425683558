import React from "react";
import "./Pages.css";
import ServiceItem from "./ServiceItem";
import servicePages from "./ServicePages/index";
import hisobotPages from "./Hisobot/index";
import { useHistory } from "react-router-dom";

const Service = () => {
  const { location } = useHistory();
  let pages = [];
  let title = "";
  if (location.pathname == "/service") {
    pages = servicePages();
    title = "Сервис";
  } else {
    pages = hisobotPages();
    title = "Хисобот";
  }
  return (
    <div>
      <h1 style={{ textAlign: "center", fontWeight: "bold", fontSize: "30px" }}>
        {title}
      </h1>
      <div className="service_grid">
        {pages.map(d => (
          <ServiceItem obj={d}></ServiceItem>
        ))}
      </div>
    </div>
  );
};

export default Service;
