export default (document, turi) => {
  if (document) {
    switch (turi) {
      case "maxsulot-qoldigi":
        const obj = {
          miqdor_soni: 0,
          miqdor_blok: 0,
          kirim_summa: 0,
          kirim_val_summa: 0,
          sotish_summa: 0,
          sotish_val_summa: 0,
        };

        document?.forEach((element) => {
          obj.kirim_summa +=
            (isNaN(element.kirim_som * 1) ? 0 : element.kirim_som * 1) *
            (isNaN(element.miqdor_soni * 1) ? 0 : element.miqdor_soni * 1);

          obj.kirim_val_summa +=
            (isNaN(element.kirim_val * 1) ? 0 : element.kirim_val * 1) *
            (isNaN(element.miqdor_soni * 1) ? 0 : element.miqdor_soni * 1);

          obj.sotish_summa +=
            (isNaN(element.sotish_som * 1) ? 0 : element.sotish_som * 1) *
            (isNaN(element.miqdor_soni * 1) ? 0 : element.miqdor_soni * 1);

          obj.sotish_val_summa +=
            (isNaN(element.sotish_val * 1) ? 0 : element.sotish_val * 1) *
            (isNaN(element.miqdor_soni * 1) ? 0 : element.miqdor_soni * 1);

          obj.miqdor_soni += isNaN(element.miqdor_soni * 1)
            ? 0
            : element.miqdor_soni * 1;

          obj.miqdor_blok += isNaN(element.miqdor_blok * 1)
            ? 0
            : element.miqdor_blok * 1;
        });

        return obj;
      case "agent-tolov":
        const objTolov = {
          summa_som: 0,
          summa_perech: 0,
          summa_val: 0,
        };

        document?.forEach((element) => {
          objTolov.summa_som += isNaN(element.summa_som * 1)
            ? 0
            : element.tolov_turi == 1
            ? element.summa_som * 1
            : 0;
          objTolov.summa_perech += isNaN(element.summa_som * 1)
            ? 0
            : element.tolov_turi == 3
            ? element.summa_som * 1
            : 0;
          objTolov.summa_val += isNaN(element.summa_som * 1)
            ? 0
            : element.tolov_turi == 4
            ? element.summa_som * 1
            : 0;
        });
        return objTolov;
      case "yuk-tolov":
        const objYukTolov = {
          summa_som: 0,
          summa_perech: 0,
          summa_val: 0,
        };

        document?.forEach((element) => {
          objYukTolov.summa_som += isNaN(element.summa_som * 1)
            ? 0
            : element.tolov_turi == 1
            ? element.summa_som * 1
            : 0;
          objYukTolov.summa_perech += isNaN(element.summa_som * 1)
            ? 0
            : element.tolov_turi == 3
            ? element.summa_som * 1
            : 0;
          objYukTolov.summa_val += isNaN(element.summa_som * 1)
            ? 0
            : element.tolov_turi == 4
            ? element.summa_som * 1
            : 0;
        });
        return objYukTolov;
      // yukber
      case "yukberuvchi-qoldigi":
        const objyuk = {
          debit_som: 0,
          debit_val: 0,
          kredit_som: 0,
          kredit_val: 0,
        };

        document?.forEach((element) => {
          objyuk.debit_som += isNaN(element.debit_som * 1)
            ? 0
            : element.debit_som * 1;

          objyuk.debit_val += isNaN(element.debit_val * 1)
            ? 0
            : element.debit_val * 1;
          //kredit
          objyuk.kredit_som += isNaN(element.kredit_som * 1)
            ? 0
            : element.kredit_som * 1;

          objyuk.kredit_val += isNaN(element.kredit_val * 1)
            ? 0
            : element.kredit_val * 1;
        });
        return objyuk;

      case "mijoz-qoldigi":
        const objmij = {
          debit_som: 0,
          debit_val: 0,
          kredit_som: 0,
          kredit_val: 0,
        };

        document?.forEach((element) => {
          objmij.debit_som += isNaN(element.debit_som * 1)
            ? 0
            : element.debit_som * 1;

          objmij.debit_val += isNaN(element.debit_val * 1)
            ? 0
            : element.debit_val * 1;
          //kredit
          objmij.kredit_som += isNaN(element.kredit_som * 1)
            ? 0
            : element.kredit_som * 1;

          objmij.kredit_val += isNaN(element.kredit_val * 1)
            ? 0
            : element.kredit_val * 1;
        });
        return objmij;

      case "maxsulot-kirimi":
        const objkirim = {
          miqdor_soni: 0,
          miqdor_blok: 0,
          kirim_summa: 0,
          kirim_val_summa: 0,
        };

        document?.forEach((element) => {
          objkirim.kirim_summa +=
            (isNaN(element.kirim_som * 1) ? 0 : element.kirim_som * 1) *
            (isNaN(element.miqdor_soni * 1) ? 0 : element.miqdor_soni * 1);

          objkirim.kirim_val_summa +=
            (isNaN(element.kirim_val * 1) ? 0 : element.kirim_val * 1) *
            (isNaN(element.miqdor_soni * 1) ? 0 : element.miqdor_soni * 1);
          objkirim.miqdor_soni += isNaN(element.miqdor_soni * 1)
            ? 0
            : element.miqdor_soni * 1;
          objkirim.miqdor_blok += isNaN(element.miqdor_blok * 1)
            ? 0
            : element.miqdor_blok * 1;
        });

        return objkirim;

      case "yuk_beruvchi_vozvrat":
        const objkirim2 = {
          miqdor_soni: 0,
          miqdor_blok: 0,
          kirim_summa: 0,
          kirim_val_summa: 0,
        };

        document?.forEach((element) => {
          objkirim2.kirim_summa +=
            (isNaN(element.kirim_som * 1) ? 0 : element.kirim_som * 1) *
            (isNaN(element.miqdor_soni * 1) ? 0 : element.miqdor_soni * 1);

          objkirim2.kirim_val_summa +=
            (isNaN(element.kirim_val * 1) ? 0 : element.kirim_val * 1) *
            (isNaN(element.miqdor_soni * 1) ? 0 : element.miqdor_soni * 1);
          objkirim2.miqdor_soni += isNaN(element.miqdor_soni * 1)
            ? 0
            : element.miqdor_soni * 1;
          objkirim2.miqdor_blok += isNaN(element.miqdor_blok * 1)
            ? 0
            : element.miqdor_blok * 1;
        });

        return objkirim2;

      case "sotuv-bolimi":
        const objsotuv = {
          miqdor_blok: 0,
          miqdor_soni: 0,
          sotish_summa: 0,
          sotish_val_summa: 0,
        };

        document?.forEach((element) => {
          if (element.valyuta_turi == 1) {
            objsotuv.sotish_val_summa +=
              (isNaN(element.sotish_narxi * 1) ? 0 : element.sotish_narxi * 1) *
              (isNaN(element.miqdor_soni * 1) ? 0 : element.miqdor_soni * 1);
          } else {
            objsotuv.sotish_summa +=
              (isNaN(element.sotish_narxi * 1) ? 0 : element.sotish_narxi * 1) *
              (isNaN(element.miqdor_soni * 1) ? 0 : element.miqdor_soni * 1);
          }

          objsotuv.miqdor_soni += isNaN(element.miqdor_soni * 1)
            ? 0
            : element.miqdor_soni * 1;
          objsotuv.miqdor_blok += isNaN(element.miqdor_blok * 1)
            ? 0
            : element.miqdor_blok * 1;
        });
        return objsotuv;

      case "agent-buyurtma":
        const objagent = {
          miqdor_blok: 0,
          miqdor_soni: 0,
          sotish_summa: 0,
          sotish_val_summa: 0,
        };

        document?.forEach((element) => {
          if (element.valyuta_turi == 1) {
            objagent.sotish_val_summa +=
              (isNaN(element.sotish_narxi * 1) ? 0 : element.sotish_narxi * 1) *
              (isNaN(element.miqdor_soni * 1) ? 0 : element.miqdor_soni * 1);
          } else {
            objagent.sotish_summa +=
              (isNaN(element.sotish_som * 1) ? 0 : element.sotish_som * 1) *
              (isNaN(element.miqdor_soni * 1) ? 0 : element.miqdor_soni * 1);
          }

          objagent.miqdor_soni += isNaN(element.miqdor_soni * 1)
            ? 0
            : element.miqdor_soni * 1;
          objagent.miqdor_blok += isNaN(element.miqdor_blok * 1)
            ? 0
            : element.miqdor_blok * 1;
        });

        return objagent;
      default:
        break;
    }
  }
  return {};
};
